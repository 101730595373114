import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as URL from '../routes';
import { Suggestion, SuggestionAdapter } from '../_models/suggestion.model';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {

  constructor(
    private http: HttpClient,
    private adapter: SuggestionAdapter
  ) { }

  /**
   * Get all suggestions
   */
  all(): Observable<Suggestion[]> {
    return this.http.get(URL.SUGGESTIONS).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }


 /**
  * Delete suggestion
  * @param id id of suggestion to delete
  */
  delete(id: number) {
    const url = URL.SUGGESTIONS_ID.replace('{id}', `${id}`);
    return this.http.delete(url);
  }

}
