import { Injectable } from '@angular/core';
import { JSONAdapter } from './json-adapter';

export class Role {
    id: number;
    name: string;
    displayName: string;
    descrition: string;
    createdAt: Date;
    updatedAt: Date;
}


@Injectable({
    providedIn: 'root'
})
export class RoleAdapter extends JSONAdapter<Role> {

    protected getMapper() {
        return {
          display_name: 'displayName',
          created_at: 'createdAt',
          updated_at: 'updatedAt'
        };
    }

}
