import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnomalyService } from '../_services/anomaly/anomaly.service';
import { Anomaly } from '../_models/anomaly.model';
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-edit-anomaly',
  templateUrl: './edit-anomaly.component.html',
  styleUrls: ['./edit-anomaly.component.css']
})
export class EditAnomalyComponent implements OnInit {
  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  errorText = '';
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  hasError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas
  UploadFile: File[];
  anomalyId: any = 0;
  anomaly: Anomaly = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private anomalyService: AnomalyService) { }

  ngOnInit() {
    this.actionService.addBackButton();
    this.anomalyId = this.route.snapshot.paramMap.get('id');
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      description: ['', Validators.required],
    });

    this.getAnomaly(this.anomalyId);
  }

  async getAnomaly(id: number) {
      await this.anomalyService
        .getAnomaly(id)
        .toPromise()
        .then((data: Anomaly) => {
          this.anomaly = data;
          this.form.email.setValue(this.anomaly.email);
          this.form.description.setValue(this.anomaly.description);
        })
        .catch(err => {
          this.router.navigate(['/anomalies']);
        });
  }

  upload(data: any): void {
    const params = {
      email: data.email.value,
      description: data.description.value
    };
    this.anomalyService.editAnomaly(this.anomalyId, params )
      .then((response) => {
        this.isSuccess = true;
        this.router.navigate(['/anomalies']);
      }).catch().finally(() => {
        this.isLoading = false;
        this.actionService.scrollTop();
        this.router.navigate(['/anomalies']);
      });

  }

  get form(): any {
    return this.createForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.hasError = true;
      this.errorText = 'Les champs avec (*) sont requis et doivent être valident';
      this.actionService.scrollTop();
      return;
    }
    this.isLoading = true;
    this.upload(this.form);
  }
}
