import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class RefreshComponentService {

    constructor() {}

    private subjectName = new Subject<any>(); //need to create a subject

    sendUpdate(message: any) { //the component that wants to update something, calls this fn
        this.subjectName.next({ data: message }); //next() will feed the value in Subject
    }

    getUpdate(): Observable<any> { //the receiver component calls this function
        return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
    }

}
