import { Component, OnInit } from "@angular/core";
import { SubMenuService } from "../_services/subMenu.service";
import { SubMenu } from "../_models/submenu.model";
import { Router, ActivatedRoute } from "@angular/router";
import { DATATABLE_CONFIG } from "../constants";
import { ActionService } from "../_services/actions/action.service";

declare var $;

@Component({
  selector: "app-submenus",
  templateUrl: "./submenus.component.html",
  styleUrls: ["./submenus.component.css"],
})
export class SubmenusComponent implements OnInit {
  submenus: SubMenu[];
  submenustmp: SubMenu[];
  isLoading = true;
  idToDelete = null;
  isSuccess = false;
  isError = false;
  errorText = "";
  toDelete: any = null;

  constructor(
    private submenuService: SubMenuService,
    public actionService: ActionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getSubMenus();
  }

  private jQueryInit() {
    if ($("tbody tr").length) {
      $(".data-table-default").DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  getSubMenus() {
    this.submenuService.getSubMenus().subscribe((data: SubMenu[]) => {
      this.submenus = data.reverse();
      this.submenustmp = data.reverse();
      this.isLoading = false;
      this.jQueryInit();
    });
  }

  confirmDelete(submenu: SubMenu) {
    this.actionService.confirmDelete(() => {
      this.toDelete = submenu;
    });
  }

  search(event) {
    let val = event.target.value;
    if (!val) {
      this.submenus = this.submenustmp;
    } else {
      this.submenus = this.submenustmp.filter((x) =>
        x.name.trim().toLowerCase().includes(val.trim().toLowerCase())
      );
    }
  }

  setIdToDetele(id: number) {
    this.idToDelete = id;
    this.deleteMenu();
  }

  editSubMenu(id: number) {
    this.router.navigate(["/edit-submenu", id]);
  }

  deleteMenu() {
    if (this.idToDelete != null) {
      this.submenuService
        .deleteSubMenu(this.idToDelete)
        .then((_) => {
          this.isSuccess = true;
          this.getSubMenus();
        })
        .catch((err) => {
          this.isError = true;
          this.errorText = err;
        });
    } else {
    }
  }
}
