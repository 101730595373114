import { Injectable } from '@angular/core';
import { Category, CategoryAdapter } from '../_models/category.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as URL from '../routes';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient,
    private adapter: CategoryAdapter
  ) { }

  /**
   * Get all categoies
   */
  all(): Observable<Category[]> {
    return this.http.get(URL.CATEGORIES).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }

}
