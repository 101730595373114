import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_services/auth/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { CategoryComponent } from './category/category.component';
import { AnomalyComponent } from './anomaly/anomaly.component';
import { PublicityComponent } from './publicity/publicity.component';
import { CreateAnomalyComponent } from './create-anomaly/create-anomaly.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { CreatePublicityComponent } from './create-publicity/create-publicity.component';
import { EditAnomalyComponent } from './edit-anomaly/edit-anomaly.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { EditPublicityComponent } from './edit-publicity/edit-publicity.component';
import { MenusComponent } from './menus/menus.component';
import { PlansComponent } from './plans/plans.component';
import { SubmenusComponent } from './submenus/submenus.component';
import { CreateMenuComponent } from './create-menu/create-menu.component';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { CreateSubmenuComponent } from './create-submenu/create-submenu.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';
import { EditPlanComponent } from './edit-plan/edit-plan.component';
import { EditSubmenuComponent } from './edit-submenu/edit-submenu.component';
import { UsersComponent } from './users/users.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { EditPermissionsComponent } from './users/edit-permissions/edit-permissions.component';
import { CreateNotificationComponent } from './create-notification/create-notification.component';
import { LgBgImgSettingComponent } from './lg-bg-img-setting/lg-bg-img-setting.component';
import { BgImageAddComponent } from './lg-bg-img-setting/bg-image-add/bg-image-add.component';
import { ProfileComponent } from './profile/profile.component';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { ServicesComponent } from './services/services.component';
import { CreateServiceComponent } from './services/create-service/create-service.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { MessageServicesComponent } from './message-services/message-services.component';
import { MessageServiceMunicipalsComponent } from './message-service-municipals/message-service-municipals.component';
import { GenericProductsComponent } from './generic-products/generic-products.component';
import { AddGenericProductsComponent } from './generic-products/add-generic-products/add-generic-products.component';
import { EditGenericProductsComponent } from './generic-products/edit-generic-products/edit-generic-products.component';
import { AddProductComponent } from './articles/products/add-product/add-product.component';
import { EditProductComponent } from './articles/products/edit-product/edit-product.component';
import { TownshipComponent } from './township/township.component';
import { CreateTownshipComponent } from './township/create-township/create-township.component';

const routes: Routes = [
  { path: '', component: LoginComponent },

  { path: 'login', component: LoginComponent },

  { path: 'home', data: { authorised: ['superadministrator', 'administrator']}, component: HomeComponent, canActivate: [AuthGuard] },

  {
    path: 'attributes',
    loadChildren:
      () => import('./Attribute/attribute.module').then(m => m.AttributeModule),
  },

  {
    path: 'articles',
    loadChildren:
      () => import('./Article/article.module').then(m => m.ArticleModule),
  },

  { path: 'logout', component: LogoutComponent },

  { path: 'categories', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: CategoryComponent },
  { path: 'anomalies', data: { authorised: ['superadministrator', 'administrator']}, canActivate: [AuthGuard], component: AnomalyComponent },
  { path: 'publicities', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: PublicityComponent },
  { path: 'new-anomaly', data: { authorised: ['superadministrator', 'administrator']}, canActivate: [AuthGuard], component: CreateAnomalyComponent },
  { path: 'new-category', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: CreateCategoryComponent },
  { path: 'new-publicity', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: CreatePublicityComponent},
  { path: 'edit-anomaly/:id', data: { authorised: ['superadministrator', 'administrator']}, canActivate: [AuthGuard], component: EditAnomalyComponent },
  { path: 'edit-category/:id', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: EditCategoryComponent },
  { path: 'edit-publicity/:id', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: EditPublicityComponent },
  { path: 'menus', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: MenusComponent },
  { path: 'plans', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: PlansComponent },
  { path: 'submenus', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: SubmenusComponent },
  { path: 'new-menu', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: CreateMenuComponent },
  { path: 'new-plan', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: CreatePlanComponent },
  { path: 'new-submenu', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: CreateSubmenuComponent },
  { path: 'edit-menu/:id', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: EditMenuComponent },
  { path: 'edit-plan/:id', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: EditPlanComponent },
  { path: 'edit-submenu/:id', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: EditSubmenuComponent },
  { path: 'users', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: UsersComponent },
  { path: 'users/add', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: AddUserComponent },
  { path: 'users/edit/:id', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: EditUserComponent },
  { path: 'users/:id/permissions', data: { authorised: ['superadministrator']}, component: EditPermissionsComponent },

  { path: 'generic-products', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: GenericProductsComponent },
  { path: 'generic-products/add', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: AddGenericProductsComponent },
  { path: 'generic-products/edit/:id', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: EditGenericProductsComponent },
  // { path: 'generic-products/:id/products', data: { authorised: ['superadministrator']}, component: EditPermissionsComponent },

  { path: 'create-notification', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: CreateNotificationComponent},
  { path: 'lg-bg-img-setting', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: LgBgImgSettingComponent},
  { path: 'lg-bg-img-setting/add', data: { authorised: ['superadministrator']}, canActivate: [AuthGuard], component: BgImageAddComponent},
  { path: 'profile', data: { authorised: ['superadministrator', 'administrator']}, component: ProfileComponent },
  { path: 'services', data: { authorised: ['superadministrator', 'administrator']}, component: ServicesComponent },
  { path: 'services/create', data: { authorised: ['superadministrator', 'administrator']}, component: CreateServiceComponent },
  { path: 'suggestions', data: { authorised: ['superadministrator', 'administrator']}, component: SuggestionsComponent },
  { path: 'message-services', data: { authorised: ['superadministrator', 'administrator']}, component: MessageServicesComponent },
  { path: 'message-service-municipals', data: { authorised: ['superadministrator', 'administrator']}, component: MessageServiceMunicipalsComponent },
  //township
  { path: 'townships', data: { authorised: ['superadministrator', 'administrator']}, component: TownshipComponent },
  { path: 'townships/create', data: { authorised: ['superadministrator', 'administrator']}, component: CreateTownshipComponent },

  { path: '404', component: FourOhFourComponent },
  { path: '**', redirectTo: '404' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
