import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { PublicityService } from '../_services/publicity/publicity.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Publicity } from '../_models/publicity.model';
import { PublicityComponent } from '../publicity/publicity.component';
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-edit-publicity',
  templateUrl: './edit-publicity.component.html',
  styleUrls: ['./edit-publicity.component.css']
})
export class EditPublicityComponent implements OnInit {
  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  errorText = '';
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas
  UploadFile: File[];
  publicityId: any = 0;
  publicity: Publicity;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private publicityService: PublicityService) { }

  ngOnInit() {
    this.actionService.addBackButton();
    this.publicityId = this.route.snapshot.paramMap.get('id');
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      priority: ['', Validators.required],
      dateEnd: ['', Validators.required]
    });

    this.getPublicity(this.publicityId);
  }

  async getPublicity(id: number) {
    await this.publicityService
      .getPublicity(id)
      .toPromise()
      .then((data: Publicity) => {
        this.publicity = data;
        this.form.name.setValue(this.publicity.name);
        this.form.priority.setValue(this.publicity.priority);
        this.form.dateEnd.setValue(this.publicity.dateEnd);
      })
      .catch(err => {
        this.router.navigate(['/publicities']);
      });
}

  upload(data: any): void {
    const params = {
      name: data.name.value,
      priority: data.priority.value,
      date_end: data.dateEnd.value
    };
    this.publicityService.editPublicity(this.publicityId, params )
      .then((response) => {
        this.isSuccess = true;
        this.router.navigate(['/publicities']);
      }).catch().finally(() => {
        this.isLoading = false;
        this.actionService.scrollTop();
        this.router.navigate(['/publicities']);
      });

  }

  get form(): any {
    return this.createForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.isError = true;
      this.errorText = 'Les champs avec (*) sont requis et doivent être valident';
      this.actionService.scrollTop();
      return;
    }
    this.isLoading = true;
    this.upload(this.form);
  }

}
