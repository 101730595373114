import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { RolesService } from '../roles/roles.service';
import { PermissionsService } from '../permissions/permissions.service';
import * as STORE from 'src/app/constants';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private roleService: RolesService,
    private permissionService: PermissionsService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.checkRole(next, state);
  }

  async checkRole(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let decision = false; // false

    if (this.authService.isLogged() === true) {
      await this.roleService.getRoles().then( (roles) => {
        if (next.data.authorised) {
          for (const role of roles) {
            if (next.data.authorised.indexOf(role.name.toLowerCase()) > -1 ) {
              decision = true;
            }
          }
        }
      }).catch();

      await this.permissionService.getPermissions().then( data => {
        sessionStorage.setItem(STORE.STORED_USERS_PERMISSIONS, JSON.stringify(data));
      }).catch();

      return Promise.resolve(decision);
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return Promise.resolve(decision);
    }
  }
}
