import { Injectable } from '@angular/core';
import * as URL from '../routes';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubMenu } from '../_models/submenu.model';
import { RolesService } from './roles/roles.service';

@Injectable({
    providedIn: 'root'
})
export class SubMenuService {

    constructor(
        private httpClient: HttpClient,
        private authService: AuthenticationService,
        private rolesService: RolesService,
    ) { }

    public async addSubMenu(formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.SUBMENUS, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public async editSubMenu(id: number, formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.SUBMENUS + '/' + id, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public getSubMenus(): Observable<Array<SubMenu>> {
        return this.httpClient.get<Array<SubMenu>>(URL.SUBMENUS);
    }

    public getArticles(id): Observable<Array<any>> {
        if (!this.rolesService.isSuperAdmin()) {
            return this.httpClient.get<Array<any>>(URL.SUBMENUS + '/' + id + '/articles/user/' + this.authService.getUser().id);
        } else {
            return this.httpClient.get<Array<any>>(URL.SUBMENUS + '/' + id + '/articles');
        }
    }

    public getAttributes(id): Observable<Array<any>> {
        return this.httpClient.get<Array<any>>(URL.SUBMENUS + '/' + id + '/attributes');
    }

    public getSubMenu(id): Observable<SubMenu> {
        return this.httpClient.get<SubMenu>(URL.SUBMENUS + '/' + id);
    }

    public async deleteSubMenu(id): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .delete(URL.SUBMENUS + '/' + id)
                .toPromise();
        } else {
            return false;
        }
    }
}
