import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../routes';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getStatistics(startDate: Date, endDate: Date): Promise<any> {
    return this.httpClient.get<any>(URL.DASHBOARD + '/' + startDate + '/' + endDate).toPromise();
  }

  public getCategoryStats(startDate: Date, endDate: Date, id: number): Promise<any> {
    return this.httpClient.get<any>(URL.DASHBOARD + '/' + startDate + '/' + endDate + '/categories/' + id).toPromise();
  }
}
