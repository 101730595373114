import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()

/**
 * @description Cette classe a pour role d'ajouter auto le token a toute les requetes HTTP et
 * d'afficher la requete dans la console, c'est un filtre Angular
 * @author Descartes Fowo
 */
export class AddTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Si l'utilisateur est connecté, le token est ajouté à la requête
    if (this.authService.isLogged() && !request.headers.get('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getAccessToken()}`
        },
        withCredentials: false
      });
    }
    // Affichage de la requête sortante
    // La requête poursuit son cheminement
    return next.handle(request);
  }
}
