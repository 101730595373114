import { Injectable } from "@angular/core";
import { JSONAdapter } from "./json-adapter";

export class User {
  id: number;
  categoryId: number;
  name: string;
  email: string;
  quarter: string;
  password: string;
  tel: string;
  town: string;
  isCitizen: boolean;
  photo: string;
  lastLogin: Date;
  expiredAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  township_id?: any;
}

@Injectable({
  providedIn: "root",
})
export class UserAdapter extends JSONAdapter<User> {
  protected getMapper() {
    return {
      category_id: "categoryId",
      is_citizen: "isCitizen",
      last_login: "lastLogin",
      expired_at: "expiredAt",
      created_at: "createdAt",
      updated_at: "updatedAt",
      deleted_at: "deletedAt",
    };
  }
}
