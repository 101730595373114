import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { APIError } from "src/app/_models/api-error.model";
import { City } from "src/app/_models/township.model";
import { ActionService } from "src/app/_services/actions/action.service";
import { TownshipService } from "src/app/_services/township.service";

declare var $;

@Component({
  selector: "app-create-township",
  templateUrl: "./create-township.component.html",
  styleUrls: ["./create-township.component.scss"],
})
export class CreateTownshipComponent implements OnInit {
  createForm: FormGroup;
  error = "";
  success = "";
  loading = false;
  submitted = false;
  alreadyExist = false;
  cities: City[] = [];
  cityId: number = null;

  constructor(
    private formBuilder: FormBuilder,
    private townshipService: TownshipService,
    private actionService: ActionService
  ) {}

  ngOnInit() {
    this.actionService.addBackButton();
    this.createForm = this.formBuilder.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      description: ["", [Validators.nullValidator]],
    });

    this.townshipService.getCities().then((val) => {
      this.cities = val;
      if ($(".select2").length) {
        $(".select2").select2();
        $(".select2").on("change", (e) => (this.cityId = e.target.value));
        $(".select2").height(40);
      }
    });
  }

  get form() {
    return this.createForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction

    if (this.createForm.invalid && this.cityId == null) {
      this.error = "Formulaire incorrect !";
      this.actionService.scrollTop();
      return;
    } else {
      this.error = "";
    }

    const formData = new FormData();
    formData.append("name", this.form.name.value);
    formData.append("city_id", "" + this.cityId);
    formData.append("description", this.form.description.value);

    this.loading = true;
    this.townshipService
      .create(formData)
      .toPromise()
      .then((_) => {
        this.success = "Commune ajouté avec succès !";
        this.submitted = false;
        this.alreadyExist = false;
        this.createForm.reset();
      })
      .catch((err: APIError) => {
        this.error = err.message;
        if (err.errors.name) {
          this.alreadyExist = true;
        } else {
          this.alreadyExist = false;
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });
  }
}
