import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../_services/menu.service'
import { Attribute } from '../_models/attribute.model'
import { Menu } from '../_models/menu.model'
import { AttributeService } from '../_services/attribute.service'
import { SubMenuService } from '../_services/subMenu.service'
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-create-submenu',
  templateUrl: './create-submenu.component.html',
  styleUrls: ['./create-submenu.component.css']
})
export class CreateSubmenuComponent implements OnInit {

  createForm: FormGroup;
  isExpiredError = false;
  selectedAttributes = [];
  attributes: Attribute[];
  menus: Menu[];
  unknowError = false;
  errorText = "";
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private menuService: MenuService,
    private submenuService: SubMenuService,
    private actionService: ActionService,
    private attributeService: AttributeService) { }

  ngOnInit() {
    this.actionService.addBackButton();
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      logo: [''],
      attribute: [''],
      menu: [''],
    });
    this.getAttributes();
    this.getMenus();
  }

  addAttribute(){
    if (this.form.attribute.value) {
      let e = this.attributes.filter( x => x.id == this.form.attribute.value)[0];
      let e2 = this.selectedAttributes.filter(x => x.attribute_id == this.form.attribute.value)[0];
      if (!e2)
        this.selectedAttributes.push({ attribute_id: e.id, name: e.name, is_required: true, min_length: null, max_length: null})
    }
  }

  isRequired(id, event){
    this.selectedAttributes.filter(x => x.attribute_id == id)[0].is_required = event.target.checked;

  }

   minlength(id, event){
    if(event.target.value < 0) {
      this.selectedAttributes.filter(x => x.attribute_id == id)[0].min_length = 0;
    } else {
      this.selectedAttributes.filter(x => x.attribute_id == id)[0].min_length = event.target.value;
    }
   }

   maxlength(id, event){
     this.selectedAttributes.filter(x => x.attribute_id == id)[0].max_length = event.target.value;
   }

  removeAttribute(id) {
    this.selectedAttributes = this.selectedAttributes.filter(x => x.attribute_id !== id);
  }

  get form() {
    return this.createForm.controls;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createForm.get('logo').setValue(file);
    }
  }

  async getAttributes() {
    await this.attributeService.getAttributes().subscribe((data: Attribute[]) => {
      this.attributes = data;
    })
  }

  async getMenus() {
    await this.menuService.getMenus().subscribe((data: Menu[]) => {
      this.menus = data;
    })
  }


  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;

    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      return;
    }

    if (this.selectedAttributes.length == 0) {
      this.isError = true;
      this.errorText = "Selectionnez au moins un attribut";
      return;
    }

    const formData = new FormData();
    formData.append('logo', this.createForm.get('logo').value);
    formData.append('name', this.form.name.value);
    formData.append('description', this.form.description.value);
    formData.append('menu_id', this.form.menu.value);
    this.selectedAttributes.forEach(element => {
      formData.append('attributes[]', JSON.stringify(element))

    });
    //formData.append('attributes', JSON.stringify(this.selectedAttributes));
    formData.append('sub_menu_id', '');

    this.isLoading = true;
    this.submenuService.addSubMenu(formData)
      .then(resp => { this.menuService.reloadMenus();; this.isSuccess = true; this.router.navigate(['/submenus']) })
      .catch(err => { this.isError = true; this.errorText = err; })
      .finally(() => this.isLoading = false);
  }

}
