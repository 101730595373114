import { Component, OnInit } from '@angular/core';
import { Permission } from 'src/app/_models/permission.model';
import { Role } from 'src/app/_models/role.model';
import { PermissionService } from 'src/app/_services/permission.service';
import { User } from 'src/app/_models/user.model';
import { UserService } from 'src/app/_services/user.service';
import { ActivatedRoute } from '@angular/router';
import { DATATABLE_CONFIG } from '../../constants';
import { APIError } from 'src/app/_models/api-error.model';
import { ActionService } from 'src/app/_services/actions/action.service';
import { RolesService } from 'src/app/_services/roles/roles.service';
declare var $;

@Component({
  selector: 'app-edit-permissions',
  templateUrl: './edit-permissions.component.html',
  styleUrls: ['./edit-permissions.component.scss']
})
export class EditPermissionsComponent implements OnInit {

  chosen: boolean[] = [];
  chosenRole: boolean[] = [];
  permissions: Permission[] = [];
  roles: Role[] = [];
  user: User = new User();
  loading = false;

  error = '';
  success = '';
  errorRole = '';
  successRole = '';

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private actionService: ActionService,
    private roleService: RolesService,
    private permissionService: PermissionService,
  ) { }

  ngOnInit() {
    this.actionService.addBackButton();
    const userId = +this.route.snapshot.paramMap.get('id');
    this.userService.find(userId).subscribe(data => {
      this.user = data;
      this.loadAllRole();
      this.loadAllPermission();
    });
    this.jQueryInit();
  }


  private loadAllPermission() {
    this.permissionService.all().subscribe(data => {
      this.permissions = data;
      this.chosen = [];
      data.forEach(p => {
        this.chosen[p.id] = false;
        this.loadUserPermission();
      });
    });
  }

  private loadUserPermission() {
    this.permissionService.getUserPermissions(this.user.id).subscribe(data => {
      data.forEach(p => {
        this.chosen[p.id] = true;
      });
    });
  }


  private loadAllRole() {
    this.roleService.all().subscribe(data => {
      this.roles = data;
      this.chosenRole = [];
      data.forEach(r => {
        this.chosenRole[r.id] = false;
        this.loadUserRole();
      });
    });
  }


  private loadUserRole() {
    this.roleService.getUserRoles(this.user.id).subscribe(data => {
      data.forEach(r => {
        this.chosenRole[r.id] = true;
      });
    });
  }

  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  addOrRemovePermission(permission: Permission) {
    this.chosen[permission.id] = !this.chosen[permission.id];
  }

  addOrRemoveRole(role: Role) {
    this.chosenRole[role.id] = !this.chosenRole[role.id];
  }


  submit() {
    this.submitPermission();
    this.submitRole();
  }


  private submitPermission() {
    this.loading = true;
    const permissionIds = [];
    this.chosen.forEach((checked, id) => {
      if (checked) {
        permissionIds.push(id);
      }
    });
    this.permissionService.syncWithUser(this.user.id, permissionIds).toPromise()
      .then(resp => {
        this.success = 'Permissions modifiées avec succès !';
      })
      .catch((err: APIError) => {
        this.error = err.message;
        // Try to show first error you see
        if (err.status === '400' && err.errors) {
          for (const key in err.errors) {
            if (key) {
              this.error = err.errors[key][0];
              break;
            }
          }
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });
  }


  private submitRole() {
    this.loading = true;
    const roleIds = [];
    this.chosenRole.forEach((checked, id) => {
      if (checked) {
        roleIds.push(id);
      }
    });
    this.roleService.syncWithUser(this.user.id, roleIds).toPromise()
      .then(resp => {
        this.successRole = 'Roles modifiées avec succès !';
      })
      .catch((err: APIError) => {
        this.errorRole = err.message;
        // Try to show first error you see
        if (err.status === '400' && err.errors) {
          for (const key in err.errors) {
            if (key) {
              this.errorRole = err.errors[key][0];
              break;
            }
          }
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });
  }
}
