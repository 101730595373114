import { Injectable } from '@angular/core';
import { DATATABLE_CONFIG } from '../../constants';

declare var $;

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor() {}

  confirmDelete(method: any) {
    method();
    $('#confirmDeleteModal').modal('toggle');
  }

  fullPlace(isShow: boolean): void {
    // alert('float : ' +  $('.main-wrapper').css('float') );
    $('.main-wrapper').css({ float: 'left', width : '100%' });
    if ( isShow && ($('.main-wrapper').css('float') === 'left') ) {
      $('.main-wrapper').css({
        float: 'right',
        width : '80%',
        transition: '0.6s ease'
      });
    } else if ( !isShow && $('.main-wrapper').css('float') === 'right') {
      $('.main-wrapper').css({
        float: 'left',
        width : '100%',
        transition: '0.6s ease'
      });
    }
  }

  setProgress(id: string, stats: any) {
      let width = 0;
      const total = stats.total === 0 ? 0.12 : stats.total;
      width = Math.round( (stats.partial / total) * 100 );
      $('#' + id).css({ width : width + '%' });
  }

  delete(model: any, service: any, id: any) {
    service.delete(model.id).toPromise()
     .then(() => {
       $('#' + id).DataTable(DATATABLE_CONFIG).row($('#tr-' + id + model.id)).remove().draw(); // update view
     }).catch();
  }

  showPictures() {
    $('#showImagesModal').modal('toggle');
  }

  litteralizePhotos(photos: any[]) {
    const json = [];
    if (photos && photos.length > 0 ) {
      for (let i = 0; i < photos.length; i++) {
        json.push({
          id: i,
          image: photos[i]
        });
      }
    }
    return json;
  }


  scrollTop() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  }


  addBackButton() {
    const htmlForBackButton = `
        <span>
            <a href="javascript:history.back()">
                <i class="zmdi zmdi-arrow-left" style="font-size: 32px;"></i>
            </a>
        </span>&nbsp;&nbsp;&nbsp;`;
    const selector = '.page-heading h3';
    $(selector).html(htmlForBackButton + $(selector).html());
  }
}
