import { Component, OnInit } from '@angular/core';
import { PlanService } from '../_services/plan.service'
import { Plan } from '../_models/plan.model'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  plans: Plan[];
  planstmp: Plan[];
  isLoading = true;
  idToDelete = null;
  isSuccess = false;
  isError = false;
  errorText = '';
  constructor(
    private planService: PlanService,
    private router: Router) { }

  ngOnInit() {
    this.getPlans();
  }

  async getPlans() {
    await this.planService.getPlans().subscribe((data: Plan[]) => {
      this.plans = data;
      this.planstmp = data;
      this.isLoading = false;
    });
  }

  setIdToDetele(id: number) {
    this.idToDelete = id;
    this.deletePlan();
  }

  editPlan(id: number) {
    this.router.navigate(["/edit-plan", id]);
  }

  search(event) {
    let val = event.target.value;
    if (!val) {
      this.plans = this.planstmp;
    } else {
      this.plans = this.planstmp.filter(x =>
        x.name
          .trim()
          .toLowerCase()
          .includes(val.trim().toLowerCase())
      );
    }
  }

  async deletePlan() {
    if (this.idToDelete != null) {
      await this.planService.deletePlan(this.idToDelete).then(data => {
        this.isSuccess = true;
        this.getPlans();
      })
        .catch(err => { this.isError = true; this.errorText = err })
    } else {

    }
  }

}
