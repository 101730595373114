import { Injectable } from '@angular/core';
import { JSONAdapter } from './json-adapter';

export class Permission {
    id: number;
    name: string;
    displayName: string;
    descrition: string;
    createdAt: Date;
    updatedAt: Date;
}


@Injectable({
    providedIn: 'root'
})
export class PermissionAdapter extends JSONAdapter<Permission> {

    protected getMapper() {
        return {
          'display_name': 'displayName',
          'created_at': 'createdAt',
          'updated_at': 'updatedAt'
        };
    }

}
