import { Injectable } from '@angular/core';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import * as URL from '../../routes';
import { AuthenticationService } from '../authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publicity } from '../../_models/publicity.model';

@Injectable({
  providedIn: 'root'
})
export class PublicityService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) { }

  async uploadPublicity(formData): Promise<any> {
    if (this.authService.isLogged()) {
      return await this.httpClient.post(URL.ROUTE_PUBLICITY, formData, {
        reportProgress: true,
        observe: 'events'
      }).toPromise();
    } else {
      return false;
    }
  }

  public async editPublicity(id: number, formData): Promise<any> {
    if (this.authService.isLogged()) {
      return await this.httpClient
        .put(URL.ROUTE_PUBLICITY + '/' + id, formData)
        .toPromise();
    } else {
      return false;
    }
  }

  public getPublicities(): Observable<Array<Publicity>> {
    return this.httpClient.get<Array<Publicity>>(URL.ROUTE_PUBLICITY);
  }

  public getPublicity(id): Observable<Publicity> {
    return this.httpClient.get<Publicity>(URL.ROUTE_PUBLICITY + '/' + id);
  }

  public async delete(id): Promise<any> {
    if (this.authService.isLogged()) {
      return await this.httpClient
        .delete(URL.ROUTE_PUBLICITY + '/' + id)
        .toPromise();
    } else {
      return false;
    }
  }
}
