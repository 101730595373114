import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/_services/service.service';
import { APIError } from 'src/app/_models/api-error.model';
import { ActionService } from 'src/app/_services/actions/action.service';

@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.scss']
})
export class CreateServiceComponent implements OnInit {

  createForm: FormGroup;
  error = '';
  success = '';
  loading = false;
  submitted = false;
  alreadyExist = false;

  constructor(
    private formBuilder: FormBuilder,
    private serviceService: ServiceService,
    private actionService: ActionService,
  ) { }

  ngOnInit() {
    this.actionService.addBackButton();
    this.createForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    });
  }

  get f() {
    return this.createForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.error = 'Formulaire incorrect !';
      this.actionService.scrollTop();
      return;
    } else {
      this.error = '';
    }

    const formData = new FormData();
    formData.append('name', this.f.name.value);

    this.loading = true;
    this.serviceService.create(formData).toPromise()
      .then(resp => {
        this.success = 'Service ajouté avec succès !';
        this.submitted = false;
        this.alreadyExist = false;
        this.createForm.reset();
      })
      .catch((err: APIError) => {
        this.error = err.message;
        if (err.errors.name) {
          this.alreadyExist = true;
        } else {
          this.alreadyExist = false;
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });
  }


}
