import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as URL from '../routes';
import { MessageServiceMunicipal, MessageServiceMunicipalAdapter } from '../_models/message-service-municipal.model';

@Injectable({
  providedIn: 'root'
})
export class MessageServiceMunicipalService {

  constructor(
    private http: HttpClient,
    private adapter: MessageServiceMunicipalAdapter
  ) { }

  /**
   * Get all MessageService
   */
  all(): Observable<MessageServiceMunicipal[]> {
    return this.http.get(URL.MESSAGE_SERVICES_MUNICIPALS).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }


 /**
  * Delete MessageService
  */
  delete(id: number) {
    const url = URL.MESSAGE_SERVICES_MUNICIPALS_ID.replace('{id}', `${id}`);
    return this.http.delete(url);
  }

}
