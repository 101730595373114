import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PlanService } from '../_services/plan.service';
import { Plan } from '../_models/plan.model';
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss']
})
export class EditPlanComponent implements OnInit {

  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  plan: Plan;
  errorText = '';
  planId = null;
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private planService: PlanService) { }

  async ngOnInit() {
    this.actionService.addBackButton();
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      amount: ['', Validators.required],
      validity: ['', Validators.required],
    });
    this.planId = this.route.snapshot.paramMap.get('id');
    await this.getAttribute();
  }

  async getAttribute() {
    await this.planService
      .getPlan(this.planId)
      .toPromise()
      .then((data: Plan) => {
        this.plan = data;
        this.form.name.setValue(this.plan.name);
        this.form.amount.setValue(this.plan.amount);
        this.form.validity.setValue(this.plan.validity);
        this.form.description.setValue(this.plan.description);
      })
      .catch(err => {
        this.router.navigate(['/plans']);
      });
  }

  get form() {
    return this.createForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      return;
    }

    const formData = new FormData();

    formData.append('name', this.form.name.value);
    formData.append('amount', this.form.amount.value);
    formData.append('validity', this.form.validity.value);
    formData.append('description', this.form.description.value);
    this.isLoading = true;
    this.planService.editPlan(this.planId, formData)
      .then(resp => {
        this.isSuccess = true;
        this.router.navigate(['/plans']);
       })
      .catch(err => {
        this.isError = true;
        this.errorText = err;
       })
      .finally(() => this.isLoading = false);
  }

}
