import { Component, OnInit } from '@angular/core';
import { Publicity } from '../_models/publicity.model';
import { PublicityService } from '../_services/publicity/publicity.service';
import { Router } from '@angular/router';
import { ActionService } from '../_services/actions/action.service';
import { DATATABLE_CONFIG } from '../constants';

declare var $;

@Component({
  selector: 'app-publicity',
  templateUrl: './publicity.component.html',
  styleUrls: ['./publicity.component.css']
})
export class PublicityComponent implements OnInit {
  publicities: Publicity[];
  isMenuLoading = true;
  idToDelete = null;
  isSuccess = false;
  isError = false;
  errorText = '';

  // extras
  success = '';
  toDelete: any = null;
  photos: any = null;

  constructor(
    private publicityService: PublicityService,
    public actionService: ActionService,
    private router: Router) { }

  ngOnInit() {
    this.jQueryInit();
    this.getPublicities();
  }

  async getPublicities() {
    await this.publicityService.getPublicities().subscribe((data: Publicity[]) => {
      this.publicities = data;
      this.isMenuLoading = false;
    });
  }

  toParse(photos: any) {
    return JSON.parse(photos);
  }

  editPublicity(id: number) {
    this.router.navigate(['/edit-publicity', id]);
  }

  confirmDelete(publicity) {
    this.actionService.confirmDelete(() => {
      this.toDelete = publicity;
    });
  }

  deletePublicity(publicity: any) {
    if (publicity != null) {
      this.publicities = this.publicities.filter(item => item.id !== publicity.id);
      this.success = `${publicity.name} a été supprimé avec succès !`;
      this.actionService.delete(publicity, this.publicityService, 'publicity');
    }
  }

  showImages(publicity: any): void {
    this.photos = this.toParse(publicity.photos).images;
    this.actionService.showPictures();
  }

  // pagination
  private jQueryInit() {
    $('.data-table-default').DataTable(DATATABLE_CONFIG);
  }

}
