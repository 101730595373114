import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "src/app/_services/user.service";
import { MustMatch } from "src/app/_helpers/validators.helpers";
import { CategoryService } from "src/app/_services/category.service";
import { APIError } from "src/app/_models/api-error.model";
import { User } from "src/app/_models/user.model";
import { ActivatedRoute } from "@angular/router";
import { Category } from "src/app/_models/category.model";
import { ActionService } from "src/app/_services/actions/action.service";
import { TownshipService } from "src/app/_services/township.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit, AfterViewInit {
  updateForm: FormGroup;
  error = "";
  success = "";
  loading = false;
  submitted = false;
  categories: Category[] = [];
  user: User = new User();
  cities: any;
  townships: any = [];

  emailAlreadyTaken = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private actionService: ActionService,
    private categoryService: CategoryService,
    private townshipService: TownshipService
  ) {}

  ngOnInit() {
    this.actionService.addBackButton();
    this.initForm();
    this.categoryService.all().subscribe((data) => (this.categories = data));
    this.townshipService.getTowshipsByCity().then((val) => {
      this.cities = val;
    });
    const userId = +this.route.snapshot.paramMap.get("id");
    this.userService.find(userId).subscribe((data) => {
      this.user = data;
      this.initForm(true);
    });

  }

  ngAfterViewInit(): void {
    const min = new Date(
      new Date().getTime() +
        24 * 60 * 60 * 1000 -
        new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    document.getElementById("dt").setAttribute("min", min);
  }

  townschip(event) {
    this.townships = this.cities.find(
      (val) => val.id == event.target.value
    ).townships;
    console.log(this.townships);

  }

  getUserExpiredAtDateString() {
    let date = "";
    if (this.user && this.user.expiredAt) {
      date = `${this.user.expiredAt}`;
      date = date.split(" ")[0];
    }
    return date;
  }

  initForm(withUser: boolean = false) {
  if (withUser) {
      let tmp = this.cities.find(val => val.name == this.user.town);
      this.townships = tmp ? tmp.townships : [];
      this.updateForm = this.formBuilder.group(
        {
          name: [
            this.user.name,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(100),
            ],
          ],
          email: [this.user.email, [Validators.required, Validators.email]],
          password: ["", Validators.minLength(4)],
          confirmPassword: [""],
          tel: [
            this.user.tel,
            Validators.pattern("^(\\+237|00237|237)?6[2356789][0-9]{7}"),
          ],
          quarter: [this.user.quarter],
          town: [tmp ? tmp.id : ""],
          is_citizen: [this.user.isCitizen],
          category_id: [this.user.categoryId, Validators.required],
          expired_at: [this.getUserExpiredAtDateString()],
          photo: [""],
          township_id: [this.user.township_id, Validators.required],
        },
        {
          validator: MustMatch("password", "confirmPassword"),
        }
      );
    } else {
      this.updateForm = this.formBuilder.group(
        {
          name: [
            "",
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(100),
            ],
          ],
          email: ["", [Validators.required, Validators.email]],
          password: ["", [Validators.required, Validators.minLength(4)]],
          confirmPassword: ["", Validators.required],
          tel: [
            "",
            Validators.pattern("^(\\+237|00237|237)?6[2356789][0-9]{7}"),
          ],
          quarter: [""],
          town: [""],
          is_citizen: [false],
          category_id: ["", Validators.required],
          expired_at: [""],
          photo: [""],
          township_id: ["", Validators.required],
        },
        {
          validator: MustMatch("password", "confirmPassword"),
        }
      );
    }
  }

  get f() {
    return this.updateForm.controls;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.updateForm.get("photo").setValue(file);
    }
  }

  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.updateForm.invalid) {
      this.error = "Formulaire incorrect !";
      this.actionService.scrollTop();
      return;
    } else {
      this.error = "";
    }

    let town_name = this.cities.find(val => val.id == this.f.town.value).name;

    const formData = new FormData();
    formData.append("name", this.f.name.value);
    formData.append("email", this.f.email.value);
    if (this.f.password.value) {
      formData.append("password", this.f.password.value);
    }
    formData.append("tel", this.f.tel.value);
    formData.append("quarter", this.f.quarter.value);
    formData.append("town", town_name);
    formData.append("is_citizen", this.f.is_citizen.value ? "1" : "0");
    formData.append("category_id", this.f.category_id.value);
    formData.append("category_id", this.f.category_id.value);
    formData.append("township_id", this.f.township_id.value);
    if (this.f.photo.value) {
      formData.append("photo", this.updateForm.get("photo").value);
    }

    this.loading = true;
    this.userService
      .update(this.user.id, formData)
      .toPromise()
      .then((resp) => {
        this.success = "Utilisateur ajouté avec succès !";
        this.submitted = false;
        this.emailAlreadyTaken = false;
      })
      .catch((err: APIError) => {
        this.error = err.message;
        if (err.errors.email) {
          this.emailAlreadyTaken = true;
        } else {
          this.emailAlreadyTaken = false;
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });
  }
}
