import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { MustMatch } from 'src/app/_helpers/validators.helpers';
import { CategoryService } from 'src/app/_services/category.service';
import { APIError } from 'src/app/_models/api-error.model';
import { User } from 'src/app/_models/user.model';
import { ActivatedRoute } from '@angular/router';
import { Category } from 'src/app/_models/category.model';
import { AuthenticationService } from '../_services/authentication.service';
import { ActionService } from '../_services/actions/action.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  updateForm: FormGroup;
  error = '';
  success = '';
  loading = false;
  submitted = false;
  categories: Category[] = [];
  user: User = new User();

  emailAlreadyTaken = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthenticationService,
    private categoryService: CategoryService,
    private actionService: ActionService
  ) { }

  ngOnInit() {
    this.categoryService.all().subscribe(data => this.categories = data);
    this.user = this.authService.getUser();
    this.initForm();
    this.actionService.addBackButton();
  }


  initForm() {
    this.updateForm = this.formBuilder.group({
      name: [this.user.name, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      email: [this.user.email, [Validators.required, Validators.email]],
      password: ['', Validators.minLength(4)],
      confirmPassword: [''],
      tel: [this.user.tel, Validators.pattern('^(\\+237|00237|237)?6[2356789][0-9]{7}')],
      quarter: [this.user.quarter],
      town: [this.user.town],
      is_citizen: [this.user.isCitizen],
      category_id: [this.user.categoryId, Validators.required],
      photo: ['']
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }


  get f() {
    return this.updateForm.controls;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.updateForm.get('photo').setValue(file);
    }
  }


  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.updateForm.invalid) {
      this.error = 'Formulaire incorrect !';
      return;
    } else {
      this.error = '';
    }

    const formData = new FormData();
    formData.append('name', this.f.name.value);
    formData.append('email', this.f.email.value);
    if (this.f.password.value) {
      formData.append('password', this.f.password.value);
    }
    formData.append('tel', this.f.tel.value);
    formData.append('quarter', this.f.quarter.value);
    formData.append('town', this.f.town.value);
    formData.append('is_citizen', this.f.is_citizen.value ? '1' : '0');
    formData.append('category_id', this.f.category_id.value);
    if (this.f.photo.value) {
      formData.append('photo', this.updateForm.get('photo').value);
    }

    this.loading = true;
    this.userService.update(this.user.id, formData).toPromise()
      .then(resp => {
        this.success = 'Profile changé avec succès !';
        this.submitted = false;
        this.emailAlreadyTaken = false;
      })
      .catch((err: APIError) => {
        this.error = err.message;
        if (err.errors.email) {
          this.emailAlreadyTaken = true;
        } else {
          this.emailAlreadyTaken = false;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }


}
