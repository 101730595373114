import { Component, OnInit } from '@angular/core';
import { APIError } from 'src/app/_models/api-error.model';
import { ActionService } from 'src/app/_services/actions/action.service';
import { BgImageService } from 'src/app/_services/bg-image.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bg-image-add',
  templateUrl: './bg-image-add.component.html',
  styleUrls: ['./bg-image-add.component.scss']
})
export class BgImageAddComponent implements OnInit {

  formData: FormData;
  error = '';
  success = '';
  loading = false;
  submitted = false;

  constructor(
    private bgImageService: BgImageService,
    private actionService: ActionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.actionService.addBackButton();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formData = new FormData;
      this.formData.append('file', file);
    }
  }

  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (!this.formData || !this.formData.has('file')) {
      this.error = "L'image est obligatoire !";
      this.actionService.scrollTop();
      return;
    } else {
      this.error = '';
    }


    this.loading = true;
    this.bgImageService.create(this.formData).toPromise()
      .then(resp => {
        this.success = 'Image ajouté avec succès !';
        this.submitted = false;
        this.router.navigateByUrl("/lg-bg-img-setting");
      })
      .catch((err: APIError) => {
        this.error = err.message;
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });
  }
}
