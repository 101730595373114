import { Injectable, Input, Output, EventEmitter, Component } from '@angular/core';
import * as URL from '../routes';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Menu } from '../_models/menu.model';
import { RolesService } from './roles/roles.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    @Output() fire: EventEmitter<any> = new EventEmitter();

    constructor(
        private httpClient: HttpClient,
        private authService: AuthenticationService,
        private rolesService: RolesService,
    ) { }

    reloadMenus() {
        this.fire.emit(true);
    }

    getEmittedValue() {
        return this.fire;
    }

    public async addMenu(formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.MENUS, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public async editMenu(id: number, formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.MENUS + '/' + id, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public getMenus(): Observable<Array<Menu>> {
        if (!this.rolesService.isSuperAdmin()) {
            return this.httpClient.get<Array<Menu>>(URL.MENUS + '/user/' + this.authService.getUser().id);
        } else {
            return this.httpClient.get<Array<Menu>>(URL.MENUS);
        }
    }

    public getMenu(id): Observable<Menu> {
        return this.httpClient.get<Menu>(URL.MENUS + '/' + id);
    }

    public async deleteMenu(id): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .delete(URL.MENUS + '/' + id)
                .toPromise();
        } else {
            return false;
        }
    }
}
