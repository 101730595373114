import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import {  HttpClient } from '@angular/common/http';
import { Role, RoleAdapter } from '../../_models/role.model';
import * as URL from '../../routes';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as CONST from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    private authService: AuthenticationService,
    private adapter: RoleAdapter,
    private httpClient: HttpClient) { }

  /**
   * @ngdoc function
   * @author Bill Gates Happi Happi
   * @return Un promise des roles de l'utilisateur connecté
   */
  public async getRoles(cacheEnabled = true): Promise<any> {
    // const headers = new HttpHeaders()
    //   .set('Content-type', 'application/x-www-form-urlencoded; charset=utf-8')
    //   .set('Accept', 'application/json');
    // const userId = this.authService.getUser().id;

    // URL.USERS + userId + '/roles'
    if (cacheEnabled && sessionStorage.getItem(CONST.CURRENT_ROLE)) {
      const roles = JSON.parse(sessionStorage.getItem(CONST.CURRENT_ROLE));
      return Promise.resolve(roles);
    }

    return await this.httpClient
      .get(URL.CONNECTED_USER_ROLES/* , {
        headers,
        withCredentials: true
      } */)
      .toPromise()
      .then( (roles: Role) => {
        sessionStorage.setItem(CONST.CURRENT_ROLE, JSON.stringify(roles));
        return Promise.resolve(roles);
      })
      .catch(err => Promise.resolve(err));
  }

  /**
   * Get all roles
   */
  all(): Observable<Role[]> {
    return this.httpClient.get(URL.ROLES).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }

  /**
   * Get roles of given user
   * @param userId id of user
   */
  getUserRoles(userId: number): Observable<Role[]> {
    const url = URL.USERS_ID_ROLES.replace('{id}', `${userId}`);
    return this.httpClient.get(url).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }


  /**
   * Synchronize chosen role with user
   * @param userId id of user
   * @param permisionsIds array of chosen permission
   */
  syncWithUser(userId: number, roleIds: number[]): Observable<Role[]> {
    const url = URL.USERS_ID_ROLES.replace('{id}', `${userId}`);
    return this.httpClient.post(url, { roles: JSON.stringify(roleIds) }).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }
  public isSuperAdmin() {
    const roles = JSON.parse(sessionStorage.getItem(CONST.CURRENT_ROLE));
    if (roles) {
      for (const role of roles) {
        if (role.name.toLowerCase() === 'superadministrator') {
          return true;
        }
      }
    }
    return false;
  }

}
