import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryService } from '../_services/category/category.service';
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnInit {
  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  errorText = '';
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pour savoir si le formulaire a été envoyé ou pas

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private categoryService: CategoryService) { }

  ngOnInit() {
    this.actionService.addBackButton();
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  get form() {
    return this.createForm.controls;
  }

  async onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    // Si la validation a echoué, on arrête l'execution de la fonction
    if (this.createForm.invalid) {
      this.isError = true;
      this.errorText = 'Ce champ est requis';
      this.actionService.scrollTop();
      return;
    }
    this.isLoading = true;
    await this.categoryService.addCategory(this.form.name.value)
      .then(resp => {
        this.isSuccess = true;
      })
      .catch(err => { this.isError = true; this.errorText = err; })
      .finally(() => {
          this.isLoading = false;
          this.actionService.scrollTop();
      });
    this.router.navigate(['/categories']);
  }

}
