import { Component, OnInit } from '@angular/core';
import { Category } from '../_models/category.model';
import { CategoryService } from '../_services/category/category.service';
import { Router } from '@angular/router';
import { ActionService } from '../_services/actions/action.service';
import { DATATABLE_CONFIG } from '../constants';

declare var $;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  categories: Category[];
  isMenuLoading = true;
  isSuccess = false;
  isError = false;
  errorText = '';

  // extras
  success = '';
  toDelete: any = null;

  constructor(
    private categoryService: CategoryService,
    public actionService: ActionService,
    private router: Router) { }

  ngOnInit() {
    this.getCategories();
  }

  async getCategories() {
    await this.categoryService.getCategories().subscribe((data: Category[]) => {
      this.categories = data;
      this.isMenuLoading = false;
      this.jQueryInit();
    });
  }

  editCategory(id: number) {
    this.router.navigate(['/edit-category', id]);
  }

  confirmDelete(category: Category) {
    this.actionService.confirmDelete(() => {
      this.toDelete = category;
    });
  }

  deleteCategory(category: Category) {
    if (category != null) {
      this.categories = this.categories.filter(item => item.id !== category.id);
      this.success = `${category.name} a été supprimé avec succès !`;
      this.actionService.delete(category, this.categoryService, 'category');
    }
  }

  // pagination
  private jQueryInit() {
    $('.data-table-default').DataTable(DATATABLE_CONFIG);
  }

}
