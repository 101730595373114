import { Injectable } from '@angular/core';
import { JSONAdapter } from './json-adapter';

export class Suggestion {
    id: number;
    name: string;
    userId: number;
    email: string;
    description: string;
    address: string;
    identite: string;
    firstName: string;
    lastName: string;
    photo: string;
    phone: string;
    longitude: string;
    latitude: string;
    createdAt: Date;
    updatedAt: Date;
}


@Injectable({
    providedIn: 'root'
})
export class SuggestionAdapter extends JSONAdapter<Suggestion> {

    protected getMapper() {
      return {
        user_id: 'userId',
        first_name: 'firstName',
        last_name: 'lastName',
        created_at: 'createdAt',
        updated_at: 'updatedAt'
      };
    }

}
