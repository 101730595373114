import { Component, OnInit } from '@angular/core';
import { MessageServiceMunicipal } from '../_models/message-service-municipal.model';
import { MessageServiceMunicipalService } from '../_services/message-service-municipal.service';
import { DATATABLE_CONFIG } from '../constants';

declare var $;

@Component({
  selector: 'app-message-service-municipals',
  templateUrl: './message-service-municipals.component.html',
  styleUrls: ['./message-service-municipals.component.scss']
})
export class MessageServiceMunicipalsComponent implements OnInit {

  data: MessageServiceMunicipal[] = [];
  success = '';
  error = '';
  serviceToShow: MessageServiceMunicipal = new MessageServiceMunicipal();
  toDelete: MessageServiceMunicipal = new MessageServiceMunicipal();
  toShow: MessageServiceMunicipal = new MessageServiceMunicipal();

  constructor(private messageServiceMunicipalService: MessageServiceMunicipalService) { }

  ngOnInit() {
    this.messageServiceMunicipalService.all().subscribe(data => this.data = data);
    this.jQueryInit();
  }


  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  confirmDelete(messageServiceMunicipal: MessageServiceMunicipal) {
    this.toDelete = messageServiceMunicipal;
    $('#confirmDeleteModal').modal('toggle');
  }

  show(messageServiceMunicipal: MessageServiceMunicipal) {
    this.toShow = messageServiceMunicipal;
    $('#showModal').modal('toggle');
  }

  delete(messageServiceMunicipal: MessageServiceMunicipal) {
    this.messageServiceMunicipalService.delete(messageServiceMunicipal.id).toPromise()
      .then(() => {
        $('.data-table-default').DataTable(DATATABLE_CONFIG).row($('#tr' + messageServiceMunicipal.id)).remove().draw(); // update view
        this.data = this.data.filter(item => item.id !== messageServiceMunicipal.id); // update logic
        this.success = `Message service municipal #${messageServiceMunicipal.id} a été supprimé avec succès !`; // show success message
        this.error = '';
      })
      .catch(err => this.error = err.message);
  }

}
