import { Component, OnInit } from '@angular/core';
import { Suggestion } from '../_models/suggestion.model';
import { SuggestionService } from '../_services/suggestion.service';
import { DATATABLE_CONFIG } from '../constants';

declare var $;

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {

  data: Suggestion[] = [];
  success = '';
  error = '';
  toDelete: Suggestion = new Suggestion();
  toShow: Suggestion = new Suggestion();

  constructor(private suggestionService: SuggestionService) { }

  ngOnInit() {
    this.suggestionService.all().subscribe(data => this.data = data);
    this.jQueryInit();
  }


  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  confirmDelete(suggestion: Suggestion) {
    this.toDelete = suggestion;
    $('#confirmDeleteModal').modal('toggle');
  }


  show(suggestion: Suggestion) {
    this.toShow = suggestion;
    $('#showModal').modal('toggle');
  }

  delete(suggestion: Suggestion) {
    this.suggestionService.delete(suggestion.id).toPromise()
      .then(() => {
        $('.data-table-default').DataTable(DATATABLE_CONFIG).row($('#tr' + suggestion.id)).remove().draw(); // update view
        this.data = this.data.filter(item => item.id !== suggestion.id); // update logic
        this.success = `Suggestion #${suggestion.id} a été supprimé avec succès !`; // show success message
        this.error = '';
      })
      .catch(err => this.error = err.message);
  }

}
