
/**
 * Type for Mapper,
 * key are json's key and value are model's key
 */
interface MapperType {
    [key: string]: string;
}

/**
 * Class to adapt model to json and json to model
 */
export abstract class JSONAdapter<T> {

    /**
     * Swap object's key with value
     * @example {'a':'b'} => {'b':'a'}
     * @param obj object to swap
     * @return swaped object
     */
    private swapKeyValue(obj) {
      let ret = {};
      for (let key in obj) {
          ret[obj[key]] = key;
      }
      return ret;
    }


    /**
     * Create new object with mapper's key added,
     * values of these keys are value of corresponding keys in mapper object
     * @param mapper the mapper
     * @param obj object to adapt
     */
    private adapt(mapper: MapperType, obj: any): any {
      let adaptedObj: any = Object.assign({}, obj);
      const fields: Array<string> = Object.keys(mapper);
      for (let field of fields) {
          const targetField: any = mapper[field];
          adaptedObj[targetField] = obj[field];
      }
      return adaptedObj;
    }

    /**
     * Adapt json to model
     * @param json json obect to adapt
     * @return model
     */
    fromJson(json: any): T {
      return this.adapt(this.getMapper(), json);
    }


    /**
     * Adapt model to json
     * @param model model to adpat
     * @return json object
     */
    toJson(model: T): any {
      const mapper = this.swapKeyValue(this.getMapper());
      return this.adapt(mapper, model);
    }

    /**
     * Get mapper object
     */
    protected abstract getMapper(): MapperType;

}
