import { Injectable } from '@angular/core';
import { JSONAdapter } from './json-adapter';

export class GenericProduct {
    id: number;
    subMenuId: number;
    name: string;
    slug: string;
    description: string;
    pictures: string[];
    createdAt: Date;
    deletedAt: Date;
}


@Injectable({
    providedIn: 'root'
})
export class GenericProductAdapter extends JSONAdapter<GenericProduct> {

    protected getMapper() {
        return {
          submenu_id: 'subMenuId',
          created_at: 'createdAt',
          updated_at: 'updatedAt'
        };
    }

}
