import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { RolesService } from '../_services/roles/roles.service';
import { BgImageService } from '../_services/bg-image.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isExpiredError = false;
  isNotAdminError = false;
  unknowError = false;
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  notLogged = false; // S'il vaut true, le message de connexion avant de continuer est affiché
  isLoginError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isLoginSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private bgImageService: BgImageService,
    private router: Router) {
  }

  ngOnInit() {

    if ( this.authService.isLogged() ) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/login']);
    }

    // Validateur du formulaire
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.route.queryParams.subscribe(params => {
      if (params.returnUrl) {
        this.notLogged = true;
      }
    });

    this.bgImageService.getForToday().toPromise()
    .then(bg => {
      if (bg && bg.path) {
        document.getElementById('bg-image').setAttribute('style', `background-image: url(${bg.path});`);
      }
    })
    .catch();
  }


  get form() {
    return this.loginForm.controls;
  }

  /**
   * @ngdoc function
   * @description Cette fonction fait verifie si les params de connexion sont
   * bien remplis et fait appel a la methode login de AuthenticationService
   * @author Descartes Fowo and richie
   */
  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    this.isLoginError = false;
    this.isLoginSuccess = false;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.authService.login(this.form.email.value, this.form.password.value)
      .then(resp => this.afterReponse(resp))
      .catch(err => this.afterReponse(err))
      .finally(() => this.isLoading = false);
  }

  async afterReponse(resp) {
    if (resp.token) {
      this.isLoginSuccess = true;
      await this.rolesService.getRoles(false).then( (data: any) => {
        const roles = data;
        let redirected = false;
        for (const role of roles) {
          if ( role.name.toLowerCase() === 'administrator' ) {
            redirected = true;
            this.router.navigate(['/home']);
          } else if ( role.name.toLowerCase() === 'superadministrator' ) {
            redirected = true;
            this.router.navigate(['/home']);
          }
        }

        if (!redirected) {
          this.isLoginSuccess = false;
          this.isNotAdminError = true;
        }
      }).catch((error) => {
      });
      return;
    }
    switch (resp.code) {
      case 'AUTH_01':
        this.isLoginError = true;
        break;
      case 'AUTH_02':
        this.isExpiredError = true;
        break;
      default:
        this.unknowError = true;
        break;
    }
  }
}
