import { Injectable } from "@angular/core";
import { JSONAdapter } from "./json-adapter";

export class Township {
  id: number;
  name: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
  city_name: string;
  city_id: number;
}

export class City {
  id: number;
  name: string;
  country_id: number;
}

@Injectable({
  providedIn: "root",
})
export class TownshipAdapter extends JSONAdapter<Township> {
  protected getMapper() {
    return {
      created_at: "createdAt",
      updated_at: "updatedAt",
    };
  }
}
