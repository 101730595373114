import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '../_services/menu.service'
import { Menu } from '../_models/menu.model'

const SIDEBARMENU = 'SIDEBARMENU';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarComponent implements OnInit, OnDestroy {

  menus: Menu[];
  isMenuLoading = true;
  subscription = null;

  constructor(private menuService: MenuService, private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.getMenuDataSession();
    this.getMenus();
    this.subscription = this.menuService.getEmittedValue()
      .subscribe(reload => reload ? this.getMenus() : null);
  }

  getMenuDataSession() {
    if(sessionStorage.getItem(SIDEBARMENU)) {
      this.isMenuLoading = false
      this.menus = JSON.parse(sessionStorage.getItem(SIDEBARMENU)!);
    }
  }

  setMenuDataSession(data: Menu[]) {
    sessionStorage.setItem(SIDEBARMENU, JSON.stringify(data));
    if(JSON.stringify(data) !== JSON.stringify(this.menus)) {
      this.getMenuDataSession();
    }
  }

  getMenus() {
    this.menuService.getMenus().toPromise().then((data: Menu[]) => {
      this.menus = data;
      this.setMenuDataSession(this.menus);
      this.isMenuLoading = false
      this._changeDetectorRef.markForCheck();
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
			this.subscription.unsubscribe();
		}

  }

}
