import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '../_services/permissions/permissions.service';
import { DashboardService } from '../_services/dashboard.service';
import { ActionService } from '../_services/actions/action.service';
import { CategoryService } from '../_services/category/category.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  startDate: Date = null;
  endDate: Date = null;
  stats: any = {};

  // show statistic on the categories
  showReturn = false;
  categories: any = [];

  categorieName = null;

  constructor(
    private dashboardService: DashboardService,
    public actionService: ActionService,
    public permissions: PermissionsService,
    public categoriesService: CategoryService
  ) { }

  ngOnInit() {
    this.fetchStats(null);
  }

  fetchStats(event): void {
    if ( !this.startDate ) {
      this.startDate = null;
    }

    if ( !this.endDate ) {
      this.endDate = null;
    }
    this.dashboardService.getStatistics(this.startDate, this.endDate)
      .then(stats => {
        this.stats = stats;
        for (const k in stats) {
          if (k) {
            this.actionService.setProgress('id-' + k, stats[k]);
          }
        }
      })
      .catch();
  }

  //

  async seeMoreOnUsers() {
    await this.categoriesService.getCategories().subscribe( (data) => {
        this.showReturn = true;
        this.categories = data;
    }, (error) => {
    });
  }

  async getStatistic(categorie: any) {
    await this.dashboardService.getCategoryStats(this.startDate, this.endDate, categorie.id)
      .then( (data) => {
        categorie.users = data.users;
      }).catch((error) => {
      });
  }

}
