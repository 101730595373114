import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicityService } from '../_services/publicity/publicity.service';
import { HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { ActionService } from '../_services/actions/action.service';
import { SubMenuService } from '../_services/subMenu.service';

@Component({
  selector: 'app-create-publicity',
  templateUrl: './create-publicity.component.html',
  styleUrls: ['./create-publicity.component.css']
})
export class CreatePublicityComponent implements OnInit {
  @ViewChild('File', { static: false }) InputFile;

  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  errorText = '';
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas
  UploadFile: File[];
  userId: number = null;

  //
  submenus: any = [];

  constructor(
    private formBuilder: FormBuilder,
    //private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private actionService: ActionService,
    private submenuService: SubMenuService,
    private publicityService: PublicityService) { }

  ngOnInit() {
    this.actionService.addBackButton();
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      priority: ['', Validators.required],
      photos: ['', Validators.required],
      submenu: ['', Validators.required],
      dateEnd: ['', Validators.required]
    });

    this.fetchSubmenus();
  }

  upload(data: any): void {
    const formData = new FormData();
    const file = this.InputFile.nativeElement;
    if (file.files.length > 0) {
      for (const blob of file.files) {
        formData.append('photos[]', blob);
      }
    }
    formData.append('name', data.name.value);
    formData.append('priority', data.priority.value);
    formData.append('submenu', data.submenu.value);
    formData.append('user_id', this.userId + '');
    formData.append('date_end', data.dateEnd.value);

    this.publicityService.uploadPublicity(formData)
      .then((_) => {
        this.isSuccess = true;
        this.router.navigate(['/publicities']);
      }).catch().finally(() => {
        this.isLoading = false;
        this.actionService.scrollTop();
        this.router.navigate(['/publicities']);
      });

  }

  fetchSubmenus() {
    this.submenuService.getSubMenus().subscribe((data) => {
      this.submenus = data;
    }, (error) => {
      console.log(error);
    });
  }

  get form(): any {
    return this.createForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    this.userId = this.authService.getUser().id;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.isError = true;
      this.errorText = 'Les champs avec (*) sont requis';
      this.actionService.scrollTop();
      return;
    }
    this.upload(this.form);
    this.isLoading = true;
  }
}
