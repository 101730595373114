import { Component, OnInit } from "@angular/core";
import { MenuService } from "../_services/menu.service";
import { Menu } from "../_models/menu.model";
import { Router, ActivatedRoute } from "@angular/router";
import { DATATABLE_CONFIG } from "../constants";
import { ActionService } from "../_services/actions/action.service";
import { RefreshComponentService } from "../_services/changeInCpmponent.service";

declare var $;

@Component({
  selector: "app-menus",
  templateUrl: "./menus.component.html",
  styleUrls: ["./menus.component.css"],
})
export class MenusComponent implements OnInit {
  menus: Menu[];
  menustmp: Menu[];
  isLoading = true;
  idToDelete = null;
  isSuccess = false;
  isError = false;
  errorText = "";
  toDelete: any = null;

  constructor(
    private menuService: MenuService,
    public actionService: ActionService,
    public changeService: RefreshComponentService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getMenus();
  }

  getMenus() {
    this.menuService
      .getMenus()
      .toPromise()
      .then((data: Menu[]) => {
        this.isLoading = false;
        this.menus = data.reverse();
        this.menustmp = data.reverse();
        this.jQueryInit();
      });
  }

  setIdToDetele(id: number) {
    this.idToDelete = id;
    this.deleteMenu();
  }

  confirmDelete(menu: Menu) {
    this.actionService.confirmDelete(() => {
      this.toDelete = menu;
    });
  }

  private jQueryInit() {
    if ($("tbody tr").length) {
      $(".data-table-default").DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  editMenu(id: number) {
    this.router.navigate(["/edit-menu", id]);
  }

  search(event) {
    let val = event.target.value;
    if (!val) {
      this.menus = this.menustmp;
    } else {
      this.menus = this.menustmp.filter((x) =>
        x.name.trim().toLowerCase().includes(val.trim().toLowerCase())
      );
    }
  }

  async deleteMenu() {
    if (this.idToDelete != null) {
      await this.menuService
        .deleteMenu(this.idToDelete)
        .then((data) => {
          this.isSuccess = true;
          this.getMenus();
          this.menuService.reloadMenus();
        })
        .catch((err) => {
          this.isError = true;
          this.errorText = err;
        });
    } else {
    }
  }
}
