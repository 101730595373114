import { Injectable, Input, Output, Component } from '@angular/core';
import * as URL from '../routes';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Plan } from '../_models/plan.model';
import { RolesService } from './roles/roles.service';

@Injectable({
    providedIn: 'root'
})
export class PlanService {

    constructor(
        private httpClient: HttpClient,
        private authService: AuthenticationService,
        private rolesService: RolesService,
    ) { }


    public async addPlan(formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.PLANS, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public async editPlan(id: number, formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.PLANS + '/' + id, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public getPlans(): Observable<Array<Plan>> {
        return this.httpClient.get<Array<Plan>>(URL.PLANS);
    }

    public getPlan(id): Observable<Plan> {
        return this.httpClient.get<Plan>(URL.PLANS + '/' + id);
    }

    public async deletePlan(id): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .delete(URL.PLANS + '/' + id)
                .toPromise();
        } else {
            return false;
        }
    }
}
