import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './_http_interceptors/error-interceptor.helper';
import { AddTokenInterceptor } from './_http_interceptors/add-token-interceptor.helper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_services/auth/auth.guard';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { FooterComponent } from './footer/footer.component';
import { LogoutComponent } from './logout/logout.component';
//import { CreateArticleComponent } from './Article/create-article/create-article.component';
import { CategoryComponent } from './category/category.component';
import { AnomalyComponent } from './anomaly/anomaly.component';
import { PublicityComponent } from './publicity/publicity.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { CreateAnomalyComponent } from './create-anomaly/create-anomaly.component';
import { EditAnomalyComponent } from './edit-anomaly/edit-anomaly.component';
import { CreatePublicityComponent } from './create-publicity/create-publicity.component';
import { EditPublicityComponent } from './edit-publicity/edit-publicity.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';
import { CreateMenuComponent } from './create-menu/create-menu.component';
import { MenusComponent } from './menus/menus.component';
import { SubmenusComponent } from './submenus/submenus.component';
import { CreateSubmenuComponent } from './create-submenu/create-submenu.component';
import { EditSubmenuComponent } from './edit-submenu/edit-submenu.component';
import { UsersComponent } from './users/users.component';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { CreateNotificationComponent } from './create-notification/create-notification.component';
import { ProfileComponent } from './profile/profile.component';
import { EditPermissionsComponent } from './users/edit-permissions/edit-permissions.component';
import { ArticleService } from './_services/article.service';
import { AttributeService } from './_services/attribute.service';
import { MenuService } from './_services/menu.service';
import { SubMenuService } from './_services/subMenu.service';
import { AuthenticationService } from './_services/authentication.service';
import { LgBgImgSettingComponent } from './lg-bg-img-setting/lg-bg-img-setting.component';
import { BgImageAddComponent } from './lg-bg-img-setting/bg-image-add/bg-image-add.component';
import { PlansComponent } from './plans/plans.component';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { EditPlanComponent } from './edit-plan/edit-plan.component';
import { ServicesComponent } from './services/services.component';
import { CreateServiceComponent } from './services/create-service/create-service.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { MessageServiceMunicipalsComponent } from './message-service-municipals/message-service-municipals.component';
import { MessageServicesComponent } from './message-services/message-services.component';
import { FilterPipe } from './filter.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GenericProductsComponent } from './generic-products/generic-products.component';
import { AddGenericProductsComponent } from './generic-products/add-generic-products/add-generic-products.component';
import { EditGenericProductsComponent } from './generic-products/edit-generic-products/edit-generic-products.component';
import { TownshipComponent } from './township/township.component';
import { CreateTownshipComponent } from './township/create-township/create-township.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    FourOhFourComponent,
    HomeComponent,
    NavBarComponent,
    SideBarComponent,
    FooterComponent,
    LogoutComponent,
    CategoryComponent,
    AnomalyComponent,
    PublicityComponent,
    CreateCategoryComponent,
    EditCategoryComponent,
    CreateAnomalyComponent,
    EditAnomalyComponent,
    CreatePublicityComponent,
    EditPublicityComponent,
    EditMenuComponent,
    CreateMenuComponent,
    MenusComponent,
    SubmenusComponent,
    CreateSubmenuComponent,
    EditSubmenuComponent,
    UsersComponent,
    FooterSectionComponent,
    AddUserComponent,
    EditUserComponent,
    CreateNotificationComponent,
    ProfileComponent,
    EditPermissionsComponent,
    LgBgImgSettingComponent,
    BgImageAddComponent,
    PlansComponent,
    CreatePlanComponent,
    EditPlanComponent,
    ServicesComponent,
    CreateServiceComponent,
    SuggestionsComponent,
    MessageServiceMunicipalsComponent,
    MessageServicesComponent,
    FilterPipe,
    GenericProductsComponent,
    AddGenericProductsComponent,
    EditGenericProductsComponent,
    TownshipComponent,
    CreateTownshipComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularEditorModule
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    MenuService,
    SubMenuService,
    AttributeService,
    ArticleService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
