import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIError } from 'src/app/_models/api-error.model';
import { SubMenuService } from '../../_services/subMenu.service';
import { GenericProductsService } from '../../_services/generic-products.service';
import { ActionService } from '../../_services/actions/action.service';

@Component({
  selector: 'app-add-generic-products',
  templateUrl: './add-generic-products.component.html',
  styleUrls: ['./add-generic-products.component.scss']
})
export class AddGenericProductsComponent implements OnInit {
  @ViewChild('File', { static: false }) InputFile;

  createForm: FormGroup;
  error = '';
  success = '';
  isSuccess = false;
  loading = false;
  submitted = false;
  submenus = [];
  UploadFile: File[];

  constructor(
    private formBuilder: FormBuilder,
    private genericProductsService: GenericProductsService,
    private subMenuService: SubMenuService,
    private actionService: ActionService
  ) { }

  ngOnInit() {
    this.actionService.addBackButton();
    this.subMenuService.getSubMenus().subscribe(data => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      this.submenus = data;
    });
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.minLength(2)]],
      pictures: ['', Validators.required],
      submenu_id: ['', Validators.required]
    });
  }

  get form() {
    return this.createForm.controls;
  }

  create(data: any): void {
    const formData = new FormData();
    const file = this.InputFile.nativeElement;
    if (file.files.length > 0) {
      for (const blob of file.files) {
        formData.append('pictures[]', blob);
      }
    }
    formData.append('name', data.name.value);
    formData.append('description', data.description.value);
    formData.append('submenu_id', data.submenu_id.value + '');

    this.genericProductsService.create(formData).toPromise()
      .then((response) => {
        this.isSuccess = true;
        this.success = 'Produit générique créé avec succès !';
        this.submitted = false;
        this.createForm.reset();
      }).catch((err: APIError) => {
        this.error = err.message;
        console.log('Dans catch ', this.error, err);
        // Try to show first error you see
        if (!this.error && err.status === '400' && err.errors) {
          // tslint:disable-next-line: forin
          for (const key in err.errors) {
            this.error = err.errors[key][0];
            break;
          }
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });

  }

  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.error = 'Formulaire incorrect !';
      return;
    } else {
      this.error = '';
    }

    this.loading = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.actionService.scrollTop();
      return;
    }
    this.create(this.form);
    this.loading = true;
  }
}
