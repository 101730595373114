import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { AnomalyService } from '../_services/anomaly/anomaly.service';
import { ActionService } from '../_services/actions/action.service';
import { GeolocationService } from '../_services/geolocation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-anomaly',
  templateUrl: './create-anomaly.component.html',
  styleUrls: ['./create-anomaly.component.css']
})
export class CreateAnomalyComponent implements OnInit {
  @ViewChild('File', { static: false }) InputFile;

  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  errorText = '';
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  hasError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas
  UploadFile: File[];
  userId: number = null;
  location: any = {
    latitude: '',
    longitude: '',
    address: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private actionService: ActionService,
    private locationService: GeolocationService,
    private router: Router,
    private anomalyService: AnomalyService) { }

  async ngOnInit() {
    this.actionService.addBackButton();
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      description: ['', Validators.required],
      photos: ['']
    });

    // localisation de l'utilisateur
    await this.locationService.getAddress().then( (data) => {
        this.location = data;
    }).catch();
  }

  upload(data: any): void {
    const formData = new FormData();
    const files = this.InputFile.nativeElement.files;
    // alert(file.files.length + '-photos');
    if (files && files.length > 0) {
      for (const file of files) {
        formData.append('photos[]', file);
      }
    }
    formData.append('email', data.email.value);
    formData.append('description', data.description.value);
    formData.append('user_id', this.userId + '');
    // ajout des coordonnées gps
    // formData.append('latitude', this.location.latitude);
    // formData.append('longitude', this.location.longitude);
    // formData.append('address', this.location.address);

    this.anomalyService.uploadAnomaly(formData)
      .then((response) => {
        this.isSuccess = true;
        this.router.navigate(['/anomalies']);
      })
      .catch()
      .finally(() => {
        this.isLoading = false;
        this.actionService.scrollTop();
        this.router.navigate(['/anomalies']);
      });

  }

  get form(): any {
    return this.createForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    this.userId = this.authService.getUser().id;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.errorText = 'Les champs avec (*) sont requis et doivent être valident';
      this.hasError = true;
      this.actionService.scrollTop();
      return;
    }
    this.isLoading = true;
    this.upload(this.form);
  }
}
