import { Component, OnInit } from '@angular/core';
import { DATATABLE_CONFIG } from '../constants';
import { BgImageService } from '../_services/bg-image.service';
import { ActionService } from '../_services/actions/action.service';

declare var $;


@Component({
  selector: 'app-lg-bg-img-setting',
  templateUrl: './lg-bg-img-setting.component.html',
  styleUrls: ['./lg-bg-img-setting.component.scss']
})
export class LgBgImgSettingComponent implements OnInit {


  bgImages: any[] = [];
  success = '';
  error = '';
  imageToShow: any = {};
  toDelete: any = {};

  constructor(
    private bgImageService: BgImageService,
    private actionService: ActionService
  ) { }

  ngOnInit() {
    this.actionService.addBackButton();
    this.bgImageService.all().subscribe(data => this.bgImages = data);
    this.jQueryInit();
  }


  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }


  confirmDelete(bg) {
    this.toDelete = bg;
    $('#confirmDeleteModal').modal('toggle');
  }

  delete(bgImage) {
    this.bgImageService.delete(bgImage.id).toPromise()
      .then(() => {
        $('.data-table-default').DataTable(DATATABLE_CONFIG).row($('#tr' + bgImage.id)).remove().draw(); //update view
        this.bgImages = this.bgImages.filter(item => item.id != bgImage.id); //update logic
        this.success = `L'image #${bgImage.id} a été supprimé avec succès !`; //show success message
      })
      .catch(err => { this.error = err })
  }

  showImage(bgImage) {
    this.imageToShow = bgImage;
    $('#showImagesModal').modal('toggle');
  }
}
