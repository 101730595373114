import { Injectable } from '@angular/core';
import { ServiceAdapter, Service } from '../_models/service.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as URL from '../routes';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    private http: HttpClient,
    private adapter: ServiceAdapter
  ) { }

  /**
   * Get all services
   */
  all(): Observable<Service[]> {
    return this.http.get(URL.SERVICES).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }


  /**
   * Create new service
   */
  create(formData: FormData): Observable<Service> {
    return this.http.post(URL.SERVICES, formData).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }


 /**
  * Delete service
  * @param id id of service to delete
  */
  delete(id: number) {
    const url = URL.SERVICES_ID.replace('{id}', `${id}`);
    return this.http.delete(url);
  }

  /**
   * Update service with given id
   */
  update(id: number, name: string): Observable<Service> {
    const url = URL.SERVICES_ID.replace('{id}', `${id}`);
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('_method', 'PUT');
    return this.http.post(url, formData).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }



  /**
   * Find service with given id
   */
  find(id: number): Observable<Service> {
    const url = URL.SERVICES_ID.replace('{id}', `${id}`);
    return this.http.get(url).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }
}
