import { Component, OnInit } from '@angular/core';
import { Service } from '../_models/service.model';
import { ServiceService } from '../_services/service.service';
import { DATATABLE_CONFIG } from '../constants';
import { APIError } from '../_models/api-error.model';

declare var $;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  services: Service[] = [];
  success = '';
  error = '';
  serviceToShow: Service = new Service();
  toDelete: Service = new Service();
  name = '';
  nameToShow = '';
  id;
  toUpdate: Service = new Service();

  constructor(
    private serviceService: ServiceService
  ) { }

  ngOnInit() {
    this.serviceService.all().subscribe(data => this.services = data);
    this.jQueryInit();
  }


  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }


  goToEdit(service: Service) {
    this.name = service.name;
    this.nameToShow = service.name;
    this.id = service.id;
    $('#editModal').modal('toggle');
  }

  confirmDelete(service: Service) {
    this.toDelete = service;
    $('#confirmDeleteModal').modal('toggle');
  }

  delete(service: Service) {
    this.serviceService.delete(service.id).toPromise()
      .then(() => {
        $('.data-table-default').DataTable(DATATABLE_CONFIG).row($('#tr' + service.id)).remove().draw(); // update view
        this.services = this.services.filter(item => item.id !== service.id); // update logic
        this.success = `${service.name} a été supprimé avec succès !`; // show success message
        this.error = '';
      })
      .catch(err => this.error = err.message);
  }

  update() {
    this.serviceService.update(this.id, this.name).toPromise()
      .then(() => {
        this.serviceService.all().subscribe(data => this.services = data);
        this.success = `${name} a été modifié avec succès !`; // show success message
        this.error = '';
      })
      .catch ((err: APIError) => {
        this.error = err.message;
        this.success = '';
        if (err.errors.name) {
          this.error = 'Il existe déjà un service du même nom !';
        }
      });
  }

}
