import { Component, OnInit } from '@angular/core';
import { Anomaly } from '../_models/anomaly.model';
import { AnomalyService } from '../_services/anomaly/anomaly.service';
import { Router } from '@angular/router';
import { ActionService } from '../_services/actions/action.service';
import { DATATABLE_CONFIG } from '../constants';

declare var $;

@Component({
  selector: 'app-anomaly',
  templateUrl: './anomaly.component.html',
  styleUrls: ['./anomaly.component.css']
})
export class AnomalyComponent implements OnInit {
  anomalies: Anomaly[] = [];
  isMenuLoading = true;
  idToDelete = null;
  isSuccess = false;
  isError = false;
  errorText = '';

  // extras   $('#confirmDeleteModal').modal('toggle');
  success = '';
  toDelete: any = null;
  photos: any = null;

  constructor(
    private anomalyService: AnomalyService,
    public actionService: ActionService,
    private router: Router) { }

  ngOnInit() {
    this.jQueryInit();
    this.getAnomalies();
  }

  async getAnomalies() {
    await this.anomalyService.getAnomalies().subscribe((data: Anomaly[]) => {
      this.anomalies = data;
      this.isMenuLoading = false;
    });
  }

  toParse(photos: any) {
    return JSON.parse(photos);
  }

  editAnomaly(id: number) {
    this.router.navigate(['/edit-anomaly', id]);
  }

  confirmDelete(anomaly) {
    this.actionService.confirmDelete(() => {
      this.toDelete = anomaly;
    });
  }

  deleteAnomaly(anomaly: any) {
    if (anomaly != null) {
      this.anomalies = this.anomalies.filter(item => item.id !== anomaly.id);
      this.success = `${anomaly.name} a été supprimé avec succès !`;
      this.actionService.delete(anomaly, this.anomalyService, 'anomaly');
    }
  }

  showImages(anomaly: any): void {
    this.photos = this.toParse(anomaly.photos).images;
    this.actionService.showPictures();
  }

  // pagination
  private jQueryInit() {
    $('.data-table-default').DataTable(DATATABLE_CONFIG);
  }

}
