import { Component, OnInit } from '@angular/core';
import { Category } from '../_models/category.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../_services/category/category.service';
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {

  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  category: Category;
  errorText = '';
  categoryId = null;
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private categoryService: CategoryService) { }

  async ngOnInit() {
    this.actionService.addBackButton();
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.categoryId = this.route.snapshot.paramMap.get('id');
    await this.getCategory();
  }

  async getCategory() {
    await this.categoryService
      .getCategory(this.categoryId)
      .toPromise()
      .then((data: Category) => {
        this.category = data;
        this.form.name.setValue(this.category.name);
      })
      .catch(err => {
        this.router.navigate(['/categories']);
      });
  }

  get form() {
    return this.createForm.controls;
  }

  async onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      return;
    }

    this.isLoading = true;
    await this.categoryService.editCategory(this.categoryId, this.form.name.value)
      .then(resp => this.isSuccess = true)
      .catch(err => { this.isError = true; this.errorText = err; })
      .finally(() => this.isLoading = false);
    this.router.navigate(['/categories']);
  }


}
