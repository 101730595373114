
/**
 * App constants
 */
export const PREFIX = 'dschang-1-clique';

export const CURRENT_USER = PREFIX + 'currentUser';
export const CURRENT_ROLE = PREFIX + 'currentRole';

/**
 * Token constants
 */
export const TOKEN_PREFIX = '_token_data.';
export const TOKEN_VALUE = PREFIX + TOKEN_PREFIX + 'access_token';
export const TOKEN_EXPIRES_AT = PREFIX + TOKEN_PREFIX + 'expires_at';
export const TOKEN_TYPE = PREFIX + TOKEN_PREFIX + 'token_type';

/**
 * Authorization constants
 */
export const GRANT_TYPE_PASSWORD = 'password';
export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';

// transfert data name
export const TRANSFERT_DATA = PREFIX + 'data.transfert';
export const CURRENT_URL_SESSION_VARNAME = 'appp-const.SESSION_VARABIABLE.URL_CURRENT_TARGET';

// stored variable
export const STORED_USER_PERMISSIONS = 'user_permissions';
export const STORED_USERS_PERMISSIONS = 'users_permissions';
/**
 * Default datatable config
 */
export const DATATABLE_CONFIG = {
  retrieve: true,
  language: {
    sProcessing: 'Traitement en cours ...',
    sLengthMenu: 'Afficher _MENU_ lignes',
    sZeroRecords: 'Aucun résultat trouvé',
    sEmptyTable: 'Aucune donnée disponible',
    sInfo: 'Lignes _START_ à _END_ sur _TOTAL_',
    sInfoEmpty: 'Aucune ligne affichée',
    sInfoFiltered: '(Filtrer un maximum de_MAX_)',
    sInfoPostFix: '',
    sSearch: 'Chercher:',
    sUrl: '',
    sInfoThousands: ',',
    sLoadingRecords: 'Chargement...',
    oPaginate: {
      sFirst: 'Premier',
      sLast: 'Dernier',
      sNext: 'Suivant',
      sPrevious: 'Précédent'
    },
    oAria: {
      sSortAscending: ': Trier par ordre croissant',
      sSortDescending: ': Trier par ordre décroissant'
    }
  }
};

