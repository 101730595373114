import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as URL from '../routes';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }


  /**
   * Create notification on menu to share
   * with all users who has subscribe to this menu
   */
  public create(menuId: number, message: string): Observable<any> {
    const url = URL.CREATE_MENU_NOTIFICATIONS.replace('{id}', `${menuId}`);
    return this.http.post(url, {message});
  }

  async uploadNotification(menuId: number, formData): Promise<any> {
    const url = URL.CREATE_MENU_NOTIFICATIONS.replace('{id}', `${menuId}`);
    if (this.authService.isLogged()) {
      return await this.http.post(url, formData, {
        reportProgress: true,
        observe: 'events'
      }).toPromise();
    } else {
      return false;
    }
  }
}
