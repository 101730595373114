(function ($) {
    "use strict";

    function loadMainJS() {
        /*File Pond*/
        if( $('.file-pond').length ) {
            FilePond.registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
            const inputElement = document.querySelector('.file-pond');
            const pond = FilePond.create( inputElement, {
                imagePreviewHeight: 140,
            });
        }
    }

    function waitBeforeLoad() {
        if ($('.file-pond').length) {
            loadMainJS();
        } else {
            setTimeout(waitBeforeLoad, 250);
        }
    }

    waitBeforeLoad();
    
})(jQuery);