import { Injectable } from '@angular/core';
import * as URL from '../routes';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Attribute } from '../_models/attribute.model';

@Injectable({
    providedIn: 'root'
})
export class AttributeService {

    constructor(
        private httpClient: HttpClient,
        private authService: AuthenticationService,
    ) { }

    public async addAttribute(formData): Promise<any> {
        if (this.authService.isLogged()) {

            return await this.httpClient
                .post(URL.CREATE_ATTRIBUTE, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public async editAttribute(id: number, formData: any): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.ATTRIBUTES + '/' + id, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public getAttributes(): Observable<Array<Attribute>> {
        return this.httpClient.get<Array<Attribute>>(URL.ATTRIBUTES);
    }

    public getAttribute(id): Observable<Attribute> {
        return this.httpClient.get<Attribute>(URL.ATTRIBUTES + '/' + id);
    }

    public async deleteAttribute(id): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .delete(URL.ATTRIBUTES + '/' + id)
                .toPromise();
        } else {
            return false;
        }
    }
}
