import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "src/app/_services/user.service";
import { MustMatch } from "src/app/_helpers/validators.helpers";
import { CategoryService } from "src/app/_services/category.service";
import { APIError } from "src/app/_models/api-error.model";
import { Category } from "src/app/_models/category.model";
import { ActionService } from "src/app/_services/actions/action.service";
import { Router } from "@angular/router";
import { TownshipService } from "src/app/_services/township.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit, AfterViewInit {
  createForm: FormGroup;
  error = "";
  success = "";
  loading = false;
  submitted = false;
  categories: Category[] = [];
  cities: any;
  townships: any = [];
  emailAlreadyTaken = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private actionService: ActionService,
    private categoryService: CategoryService,
    private townshipService: TownshipService
  ) {}

  ngOnInit() {
    this.actionService.addBackButton();
    this.categoryService.all().subscribe((data) => (this.categories = data));
    // Validateur du formulaire
    this.createForm = this.formBuilder.group(
      {
        name: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
          ],
        ],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(4)]],
        confirmPassword: ["", Validators.required],
        tel: ["", Validators.pattern("^(\\+237|00237|237)?6[2356789][0-9]{7}")],
        quarter: [""],
        town: [""],
        is_citizen: [false],
        category_id: ["", Validators.required],
        township_id: ["", Validators.required],
        expired_at: [""],
        photo: [""],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );

    this.townshipService.getTowshipsByCity().then((val) => {
      this.cities = val;
    });
  }

  ngAfterViewInit(): void {
    let min = new Date(
      new Date().getTime() +
        24 * 60 * 60 * 1000 -
        new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    document.getElementById("dt").setAttribute("min", min);
  }

  get f() {
    return this.createForm.controls;
  }

  townschip(event) {
    this.townships = this.cities.find(val => val.id == event.target.value).townships;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createForm.get("photo").setValue(file);
    }
  }

  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.error = "Formulaire incorrect !";
      this.actionService.scrollTop();
      return;
    } else {
      this.error = "";
    }

    let town_name = this.cities.find(val => val.id == this.f.town.value).name;

    const formData = new FormData();
    formData.append("name", this.f.name.value);
    formData.append("email", this.f.email.value);
    formData.append("password", this.f.password.value);
    formData.append("tel", this.f.tel.value);
    formData.append("quarter", this.f.quarter.value);
    formData.append("town", town_name);
    formData.append("is_citizen", this.f.is_citizen.value ? "1" : "0");
    formData.append("category_id", this.f.category_id.value);
    formData.append("township_id", this.f.township_id.value);
    formData.append("expired_at", this.f.expired_at.value);
    formData.append("photo", this.createForm.get("photo").value);

    this.loading = true;
    this.userService
      .create(formData)
      .toPromise()
      .then((resp) => {
        this.success = "Utilisateur ajouté avec succès !";
        this.submitted = false;
        this.emailAlreadyTaken = false;
        this.createForm.reset();
        this.router.navigateByUrl("/users");
      })
      .catch((err: APIError) => {
        this.error = err.message;
        if (err.errors.email) {
          this.emailAlreadyTaken = true;
        } else {
          this.emailAlreadyTaken = false;
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
      });
  }
}
