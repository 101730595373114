import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CONST from '../constants';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import * as URL from '../routes';
import { APIError } from '../_models/api-error.model';

@Injectable()

/**
 * @description Cette classe a pour role d'intercepter les erreurs lors des requetes (apres la reponse du serveur)
 * et de les afficher dans la console, c'est un filtre Angular
 * @author Descartes Fowo
 */
export class ErrorInterceptor implements HttpInterceptor {

  private static CUR_REQ_SESSION_VARNAME = 'ErrorInterceptor.CUR_REQ_SESSION_VARNAME';
  private static showMessage = true;

  constructor(
    private router: Router,
    // private alertService: AlertService,
    private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: false
    });
    // La requête en question est affichée
    sessionStorage.setItem(ErrorInterceptor.CUR_REQ_SESSION_VARNAME, JSON.stringify(request));
    return next.handle(request)
      .pipe(map((data: HttpResponse<any>) => {
        return data;
      }))
      .pipe(catchError((err) => {
        // Les requêtes de login ou de refresh token n'ont pas besoin de voir le token rafraichit en cas d'expiration de ce dernier
        if (request.url.includes(URL.OAUTH_TOKEN)) {
          // Si le token a expiré, l'utilisateur est deconnecté
          if (request.body.grant_type === 'refresh_token') {
            return this.logout();
          }
          return this.errorToMessage(err);
        }

        if (request.url.includes('login')) {
          return this.errorToMessage(err);
        }

        if (request.url.includes('token')) {
          return this.errorToMessage(err);
        }

        if (err && err.error && err.error.error === 'invalid_grant') {
          if (request.body.grant_type === 'refresh_token') {
            return this.logout();
          }
          return this.errorToMessage(err);
        }

        if (err && (err.status === 0 || err.status === 401)) {
          // A ce niveau, le code de retour de l'API est 401 (expiration de token)

          const now = new Date();
          const exp = this.authService.getTokenExpireDate();
          if (now.getTime() < exp.getTime()) {
            return this.logout();
          }
          ErrorInterceptor.showMessage = false;
          // On essaye de rafraîchir automatiquement le token de l'utilisateur
          return this.authService.refreshToken()
            .then(b => {
              if (b) {
                const targetUrl = sessionStorage.getItem(CONST.CURRENT_URL_SESSION_VARNAME);
                const req: HttpRequest<any> = JSON.parse(sessionStorage.getItem(ErrorInterceptor.CUR_REQ_SESSION_VARNAME));
                if (location.href !== targetUrl) {
                  this.router.navigate([targetUrl]);
                  return [];
                }
                return next.handle(req);
              } else {
                // Si le rafraichissement automatique a echoué, on le deconnecte
                return this.logout();
              }
            })
            .catch((err2) => {
              // Si le rafraichissement automatique a echoué, on le deconnecte
              return this.logout();
            });
        } else {
          return this.errorToMessage(err);
        }
      }));
  }

  logout(): any[] {
    if (ErrorInterceptor.showMessage) {
      // this.alertService.error('Votre session est arrivée à expiration !');
    } else {
      ErrorInterceptor.showMessage = true;
    }
    this.authService.logout();
    return [];
  }

  errorToMessage(err): Observable<any> {
    const error: APIError = err.error;
    return throwError(error);
  }

}
