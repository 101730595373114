import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ActionService } from '../../_services/actions/action.service';
import { SubMenuService } from '../../_services/subMenu.service';
import { GenericProductsService } from '../../_services/generic-products.service';
import { GenericProduct } from 'src/app/_models/generic-product.model';

@Component({
  selector: 'app-edit-generic-products',
  templateUrl: './edit-generic-products.component.html',
  styleUrls: ['./edit-generic-products.component.scss']
})
export class EditGenericProductsComponent implements OnInit {
  @ViewChild('File', { static: false }) InputFile;
  editForm: FormGroup;
  unknowError = false;
  errorText = '';
  successText = '';
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas
  UploadFile: File[];
  genericProductId = 0;
  submenus = [];
  genericProduct: GenericProduct;
  genericProductLoading = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private subMenuService: SubMenuService,
    private genericProductsService: GenericProductsService) { }

  ngOnInit() {
    this.actionService.addBackButton();
    this.subMenuService.getSubMenus().subscribe(data => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      this.submenus = data;
    });
    this.genericProductId = +this.route.snapshot.paramMap.get('id');
    // Validateur du formulaire
    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      submenu_id: ['', Validators.required],
      pictures: ['']
    });

    this.getGenericProduct(this.genericProductId);
  }

  async getGenericProduct(id: number) {
    await this.genericProductsService.find(id).toPromise()
      .then((data: GenericProduct) => {
        this.genericProduct = data;
        this.form.name.setValue(this.genericProduct.name);
        this.form.description.setValue(this.genericProduct.description);
        this.form.submenu_id.setValue(this.genericProduct.subMenuId);
      })
      .catch(err => {
        console.log(err);
        this.errorText = err.message;
        // this.router.navigate(['/generic-products']);
      })
      .finally(() => this.genericProductLoading = false);
  }

  update(data: any): void {
    const formData = new FormData();
    const file = this.InputFile.nativeElement;
    if (file.files.length > 0) {
      for (const blob of file.files) {
        formData.append('pictures[]', blob);
      }
    }
    formData.append('name', data.name.value);
    formData.append('description', data.description.value);
    formData.append('submenu_id', data.submenu_id.value + '');

    this.genericProductsService.update(this.genericProductId, formData).toPromise()
      .then((response) => {
        this.isSuccess = true;
        this.successText = 'Modifié avec succès !';
        this.router.navigate(['/generic-products']);
      }).catch().finally(() => {
        this.isLoading = false;
        this.actionService.scrollTop();
      });

  }

  get form(): any {
    return this.editForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.unknowError = false;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.editForm.invalid) {
      this.isError = true;
      this.errorText = 'Les champs avec (*) sont requis et doivent être valident';
      this.actionService.scrollTop();
      return;
    }
    this.isLoading = true;
    this.update(this.form);
  }

}
