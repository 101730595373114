import { Injectable } from '@angular/core';
import { JSONAdapter } from './json-adapter';

export class Category {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

@Injectable({
  providedIn: 'root'
})
export class CategoryAdapter extends JSONAdapter<Category> {

  protected getMapper() {
    return {
      created_at: 'createdAt',
      updated_at: 'updatedAt',
    };
  }

}
