import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PermissionAdapter, Permission } from '../_models/permission.model';
import { map } from 'rxjs/operators';
import * as URL from '../routes';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http: HttpClient,
    private adapter: PermissionAdapter
  ) { }

  /**
   * Get all permissions
   */
  all(): Observable<Permission[]> {
    return this.http.get(URL.PERMISSIONS).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }


  /**
   * Get permission of given user
   * @param userId id of user
   */
  getUserPermissions(userId: number): Observable<Permission[]> {
    const url = URL.USERS_ID_PERMISSIONS.replace('{id}', `${userId}`);
    return this.http.get(url).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }


  /**
   * Synchronize chosen permission with user
   * @param userId id of user
   * @param permisionsIds array of chosen permission
   */
  syncWithUser(userId: number, permisionsIds: number[]): Observable<Permission[]> {
    const url = URL.USERS_ID_PERMISSIONS.replace('{id}', `${userId}`);
    return this.http.post(url, { permissions: JSON.stringify(permisionsIds) }).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }
}
