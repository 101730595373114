import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as URL from '../routes';

@Injectable({
  providedIn: 'root'
})
export class BgImageService {

  constructor(private http: HttpClient) { }

  /**
  * Get all bg images
  */
  all(): Observable<any[]> {
    return this.http.get<any[]>(URL.LOGIN_BG_IMAGES);
  }


  /**
   * Delete image
   * @param id id of image to delete
   */
  delete(id: number): Observable<any> {
    const url = URL.LOGIN_BG_IMAGES_ID.replace('{id}', `${id}`);
    return this.http.delete(url);
  }


  /**
   * Get background image to show in login page
   */
  getForToday(): Observable<any> {
    return this.http.get(URL.LOGIN_BG_IMAGE);
  }

  /**
  * Create new bg image
  * @param formData
  */
  create(formData: FormData): Observable<any> {
    return this.http.post(URL.LOGIN_BG_IMAGES, formData);
  }
}
