import { Injectable } from '@angular/core';
import { JSONAdapter } from './json-adapter';

export class Service {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}


@Injectable({
    providedIn: 'root'
})
export class ServiceAdapter extends JSONAdapter<Service> {

    protected getMapper() {
        return {
          created_at: 'createdAt',
          updated_at: 'updatedAt'
        };
    }

}
