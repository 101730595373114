import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as URL from '../routes';
import { GenericProduct, GenericProductAdapter } from '../_models/generic-product.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GenericProductsService {

  constructor(
    private http: HttpClient,
    private adapter: GenericProductAdapter
  ) { }


  /**
   * Get all generic products
   */
  all(): Observable<GenericProduct[]> {
    return this.http.get(URL.GENERIC_PRODUCTS).pipe(
      map((data: any[]) => data.map(item => {
        item.pictures = JSON.parse(item.pictures);
        return this.adapter.fromJson(item);
      })), // Adapt each item in the raw data array
    );
  }


  /**
   * Get all generic products allowed for article product
   */
  allowedForArticle(articleId): Observable<GenericProduct[]> {
    const url = URL.GENERIC_PRODUCTS_ALLOWED_FOR_ARTICLE.replace('{id}', articleId);
    return this.http.get(url).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item)))
    ); // Adapt each item in the raw data array
  }

  /**
   * Create generic products
   * @param FormData formData
   */
  create(formData: FormData): Observable<GenericProduct> {
    return this.http.post<GenericProduct>(URL.GENERIC_PRODUCTS, formData);
  }


  /**
   * Edit generic products
   * @param number genericProductId
   * @param any data
   */
  update(genericProductId: number, data: any): Observable<GenericProduct> {
    const url = URL.SINGLE_GENERIC_PRODUCTS.replace('{idOrSlug}', `${genericProductId}`);
    return this.http.post<GenericProduct>(url, data);
  }


  /**
   * Find GenericProduct with given id
   * @parm number id
   */
  find(idOrSlug: number|string): Observable<GenericProduct> {
    const url = URL.SINGLE_GENERIC_PRODUCTS.replace('{idOrSlug}', `${idOrSlug}`);
    return this.http.get(url).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }



  /**
   * Delete generic product
   * @param id id of user to delete
   */
  delete(id: number) {
    const url = URL.SINGLE_GENERIC_PRODUCTS.replace('{idOrSlug}', `${id}`);
    return this.http.delete(url);
  }


}
