import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user.model';
import { UserService } from '../_services/user.service';
import { DATATABLE_CONFIG } from '../constants';
import { Router } from '@angular/router';

declare var $;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: User[] = [];
  success = '';
  error = '';
  userToShow: User = new User();
  toDelete: User = new User();
  emptyList = null;

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userService.all().subscribe(data => {
      this.users = data;
      if (!data || data.length === 0) {
        this.emptyList = true;
      }
      this.jQueryInit();
    });
  }


  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }


  goToEdit(id: number) {
    this.router.navigate(['users/edit', id]);
  }

  goToPermission(id: number) {
    this.router.navigateByUrl(`users/${id}/permissions`);
  }


  confirmDelete(user: User) {
    this.toDelete = user;
    $('#confirmDeleteModal').modal('toggle');
  }

  delete(user: User) {
    this.userService.delete(user.id).toPromise()
    .then(() => {
      $('.data-table-default').DataTable(DATATABLE_CONFIG).row($('#tr' + user.id)).remove().draw(); // update view
      this.users = this.users.filter(item => item.id !== user.id); // update logic
      this.success = `${user.name} a été supprimé avec succès !`; // show success message*/
    })
    .catch(err => this.error = err.message);
  }

  showImage(pUser: User) {
    this.userToShow = pUser;
    $('#showImagesModal').modal('toggle');
  }
}
