import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CONST from '../constants';
import { User } from '../_models/user.model';
import * as URL from '../routes';

@Injectable()
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) { }

  /**
   * @ngdoc function
   * @author Descartes Fowo
   * @param login Ce parametre contient le login de l'utilisateur
   * @param password Ce parametre contient le mot de passe de l'utilisateur
   * @return Un boolean valant true si la connexion a reussit et false si la connexion a echoué
   */
  public async login(email: string, password: string): Promise<any> {

    const params = new HttpParams()
      .set('email', email)
      .set('password', password)
      .set('grant_type', CONST.GRANT_TYPE_PASSWORD);

    const headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Accept', 'application/json');

    return await this.httpClient
      .post(URL.OAUTH_TOKEN, params, {
        headers,
        withCredentials: true
      })
      .toPromise()
      .then(resp => {
        // Le token et l'utilisateur sont enregistrés en local
        if (this.storeTokenAndUser(resp)) {
          return Promise.resolve(resp);
        } else {
          return Promise.resolve(false);
        }
      })
      .catch(err => Promise.resolve(err));
  }

  /**
   * @ngdoc function
   * @description Cette fonction deconnecte l'utilisateur connecté
   * @author Descartes Fowo
   * @param redirectUrl Ce parametre contient la route a attaquer apres la deconnexion
   */
  public logout(redirectUrl: string = '/login') {
    // Le token de l'utilisateur est supprimé de l'API
    if (this.isLogged()) {
      this.httpClient.delete(URL.OAUTH_TOKEN, { params: { userId: '' + this.getUser().id } }).subscribe(() => { });
    }
    // Le token de l'utilisateur est supprimé du stockage local
    this.deleteStoredTokenAndUser();
    // La session courante est nettoyé
    sessionStorage.clear();
    // Redirection apres deconnexion
    this.router.navigate([redirectUrl]);
  }

  /**
   * @author Descartes Fowo
   * @description Cette Cette fonction nous permet de savoir si un utilisateur est connecté ou pas
   * @return Un booleen valant true si un utilisateur est connecté et false sinon
   */
  public isLogged(): boolean {
    const currentUser = JSON.parse(sessionStorage.getItem(CONST.CURRENT_USER));
    const token = sessionStorage.getItem(CONST.TOKEN_VALUE);
    return Boolean(currentUser && token);
  }

  public getUser(): User {
    return JSON.parse(sessionStorage.getItem(CONST.CURRENT_USER));
  }

  public getRoles(): any {
    return JSON.parse(sessionStorage.getItem(CONST.CURRENT_ROLE));
  }

  /**
   * @ngdoc function
   * @author Descartes Fowo
   * @description Cette fonction rafraichit automatiquement le token de l'utilisateur a son expiration
   * @return Un booleen valant true si le rafraichissement a reussi et false sinon
   */
  public refreshToken(): Promise<boolean> {

    const params = new HttpParams()
      .set('grant_type', CONST.GRANT_TYPE_REFRESH_TOKEN);
    // .set('refresh_token', sessionStorage.getItem(CONST.TOKEN_REFRESH_VALUE));

    const headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Accept', 'application/json');

    return this.httpClient
      .post(URL.OAUTH_TOKEN, params, {
        headers,
        withCredentials: true
      })
      .toPromise()
      .then(resp => {
        // Le nouveau token est enregistré en local
        return this.storeTokenAndUser(resp);
      })
      .catch(err => {
        return false;
      });
  }

  /**
   * @description Cette fonction retourne le token de l'utilisateur connecté
   * @author Descartes Fowo
   */
  public getAccessToken() {
    return sessionStorage.getItem(CONST.TOKEN_VALUE);
  }

  /**
   * @ngdoc function
   * @author Descartes Fowo
   * @description Cette fonction sauvegarde le token et l'utilisateur en local
   * @param resp la reponse de l'API a la demande d'authentification
   * @return Un boolean valant true si l'operation a reussit et false sinon
   */
  private async storeTokenAndUser(resp): Promise<boolean> {
    const token = resp.token;
    const user = resp.user;
    if (resp && user && token.access_token) {
      if (this.isLogged()) {
        this.saveToken(token);
        return true;
      }
      if (user) {
        this.saveToken(token);
        this.saveUser(user);
        return true;
      }
    }
    return false;
  }

  public hasPrivilege(): Promise<boolean> {
    if (this.isLogged()) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  private saveUser(user: User) {
    sessionStorage.setItem(CONST.CURRENT_USER, JSON.stringify(user));
  }

  private saveRole(data: any) {
    sessionStorage.setItem(CONST.CURRENT_ROLE, JSON.stringify(data));
  }

  public getTokenExpireDate(): Date {
    const exp = sessionStorage.getItem(CONST.TOKEN_EXPIRES_AT);
    return new Date(JSON.parse(exp));
  }

  /**
   * @description Sauvegarde le token en stockage local
   * @author Descartes Fowo
   * @param token token de l'utilisateur
   */
  private saveToken(token) {
    sessionStorage.setItem(CONST.TOKEN_VALUE, token.access_token);
    sessionStorage.setItem(CONST.TOKEN_EXPIRES_AT, JSON.stringify(token.expires_at));
    sessionStorage.setItem(CONST.TOKEN_TYPE, token.token_type);
  }

  /**
   * @description Supprime le token de l'utilisateur et le dit utilisateur du stockage local
   * @author Descartes Fowo
   */
  private deleteStoredTokenAndUser() {
    sessionStorage.removeItem(CONST.CURRENT_USER);
    sessionStorage.removeItem(CONST.TOKEN_VALUE);
    sessionStorage.removeItem(CONST.TOKEN_EXPIRES_AT);
    sessionStorage.removeItem(CONST.TOKEN_TYPE);
  }
}
