import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {


  private GOOGLE_API = '';
  private GPS_STORAGE = 'coord_gps';
  constructor(private httpClient: HttpClient) { }

  public async getAddress(): Promise<any> {
    let position: any = null;
    await this.getLocation();
    position = JSON.parse(sessionStorage.getItem(this.GPS_STORAGE));
    await setTimeout(null, 1000);
    return Promise.resolve(position); // this.httpClient.get<any>(this.GOOGLE_API + '/' + id).toPromise();
  }
  /**
   * Tries HTML5 geolocation.
   * Wraps the Geolocation API into an observable.
   * @return An observable of Position
   */
  async getLocation(): Promise<any> {
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((pos: any) => {
        if (pos) {
          sessionStorage.setItem(this.GPS_STORAGE, JSON.stringify({
            latitude : pos.coords.latitude,
            longitude : pos.coords.longitude,
            address : null
          }));
        }
      }, (error: any) => {});
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }
}
