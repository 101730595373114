export const API = 'https://api.237en1clic.visibilitycam.com';
//export const API = 'http://localhost:8000';


export const BASE_URL: string = API + '/api';
export const VERSION = '1.0.0';

/**
 * Cette partie est dedié au route ayant pour verbe HTTP le POST
 */
export const OAUTH_TOKEN: string = BASE_URL + '/auth/token';
export const CREATE_ATTRIBUTE: string = BASE_URL + '/attributes';

/*================================================================*/

/**
 * Cette partie est dedié aux routes ayant pour verbe HTTP le GET
 */
export const CURRENT_USER: string = BASE_URL + '/current_user';
export const MENUS: string = BASE_URL + '/menus';
export const PLANS: string = BASE_URL + '/plans';
export const SUBMENUS: string = BASE_URL + '/submenus';
export const ATTRIBUTES: string = BASE_URL + '/attributes';
export const ARTICLES: string = BASE_URL + '/articles';

/*================================================================*/

/**
 * Cette partie est dedié au route ayant pour verbe HTTP le PUT
 */
export const UPDATE_USER: string = BASE_URL + '/user';

/*================================================================*/

/**
 * Cette partie est dedié au route ayant pour verbe HTTP le DELETE
 */
export const OAUTH_REVOKE_TOKEN: string = API + '/auth/revoke-token';

/**
 * Cette route permet d'avoir tous les roles et toutes les permissions d'un utilisateur connecté
 */
export const USERS: string = BASE_URL + '/users';

/**
 * Cette route permet d'avoir tous les roles d'un utilisateur connecté
 */
export const CONNECTED_USER_ROLES: string = BASE_URL + '/auth/roles';

/**
 * Cette route permet d'avoir toutes les permissions d'un utilisateur connecté
 */
export const CONNECTED_USER_PERMISSIONS: string = BASE_URL + '/auth/permissions';

export const DELETE_ATTRIBUTE: string = BASE_URL + '/attributes';

/**
 * Cette partie est dedié à la route de gestion des catgories
 */
export const ROUTE_CATEGORY: string = BASE_URL + '/categories';
export const ROUTE_ANOMALY: string = BASE_URL + '/anomalies';
export const ROUTE_PUBLICITY: string = BASE_URL + '/publicities';

// Users url
// export const USERS: string = BASE_URL + '/users';
export const USERS_ID: string = BASE_URL + '/users/{id}';

// Services url
export const SERVICES: string = BASE_URL + '/services';
export const SERVICES_ID: string = BASE_URL + '/services/{id}';

// Suggestions url
export const SUGGESTIONS: string = BASE_URL + '/suggestions';
export const SUGGESTIONS_ID: string = BASE_URL + '/suggestions/{id}';

// Message services url
export const MESSAGE_SERVICES: string = BASE_URL + '/message-services';
export const MESSAGE_SERVICES_ID: string = BASE_URL + '/message-services/{id}';

// Message services municipal url
export const MESSAGE_SERVICES_MUNICIPALS: string = BASE_URL + '/message-service-municipals';
export const MESSAGE_SERVICES_MUNICIPALS_ID: string = BASE_URL + '/message-service-municipals/{id}';

// Categories route
export const CATEGORIES: string = BASE_URL + '/categories';

// create notifications url
export const CREATE_MENU_NOTIFICATIONS: string = BASE_URL + '/menus/{id}/notifications';

// Permissions URL
export const PERMISSIONS: string = BASE_URL + '/permissions';
export const USERS_ID_PERMISSIONS: string = BASE_URL + '/users/{id}/permissions';

// Dashboard URL
export const DASHBOARD: string = BASE_URL + '/dashboard';
// Roles URL
export const ROLES: string = BASE_URL + '/roles';
export const USERS_ID_ROLES: string = BASE_URL + '/users/{id}/roles';


// Bg imgae URL
export const LOGIN_BG_IMAGE = BASE_URL + '/bg-image';
export const LOGIN_BG_IMAGES = BASE_URL + '/bg-images';
export const LOGIN_BG_IMAGES_ID = BASE_URL + '/bg-images/{id}';


// Generic products URL
export const GENERIC_PRODUCTS = BASE_URL + '/generic-products';
export const SINGLE_GENERIC_PRODUCTS = BASE_URL + '/generic-products/{idOrSlug}';
export const GENERIC_PRODUCTS_FOR_SUBMENU = BASE_URL + '/submenus/{id}/generic-products';
export const GENERIC_PRODUCTS_ALLOWED_FOR_ARTICLE = BASE_URL + '/generic-products/allowd-for-article/{id}';


// Products URL
export const ARTICLE_ID_PRODUCTS = BASE_URL + '/articles/{id}/products';
export const SINGLE_PRODUCT = BASE_URL + '/products/{idOrSlug}';

//Town And Citie URL
export const TOWNSHIP = BASE_URL + '/townships';
export const CITY = BASE_URL + '/cities';
