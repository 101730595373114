import { Injectable } from '@angular/core';
import * as URL from '../routes';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ArticleService {

    constructor(
        private httpClient: HttpClient,
        private authService: AuthenticationService,
    ) { }

    public async addArticle(formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.ARTICLES, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public async editArticle(id: number, formData): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .post(URL.ARTICLES + '/' + id, formData)
                .toPromise();
        } else {
            return false;
        }
    }

    public async deleteArticle(id): Promise<any> {
        if (this.authService.isLogged()) {
            return await this.httpClient
                .delete(URL.ARTICLES + '/' + id)
                .toPromise();
        } else {
            return false;
        }
    }

    public getArticle(id): Observable<any> {
        return this.httpClient.get<any>(URL.ARTICLES + '/' + id);
    }
}