import { Injectable } from '@angular/core';
import { UserAdapter, User } from '../_models/user.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as URL from '../routes';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private adapter: UserAdapter
  ) {}

  /**
   * Get all users
   */
  all(): Observable<User[]> {
    return this.http.get(URL.USERS).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }


  /**
   * Delete user
   * @param id id of user to delete
   */
  delete(id: number) {
    const url = URL.USERS_ID.replace('{id}',`${id}`);
    return this.http.delete(url);
  }


  /**
   * Create new user
   * @param formData
   */
  create(formData: FormData): Observable<User> {
    return this.http.post(URL.USERS, formData).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }


  /**
   * Update user with given id
   * @param number id
   * @param FormData formData
   */
  update(id: number, formData: FormData): Observable<User> {
    const url = URL.USERS_ID.replace('{id}', `${id}`);
    formData.append('_method','PUT');
    return this.http.post(url, formData).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }


  /**
   * Find user with given id
   * @param number id
   */
  find(id: number): Observable<User> {
    const url = URL.USERS_ID.replace('{id}', `${id}`);
    return this.http.get(url).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }
}
