import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user.model'
import { PermissionsService } from '../_services/permissions/permissions.service';
import { RolesService } from '../_services/roles/roles.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent implements OnInit {

  user: User;
  isAdmin: boolean = false;
  constructor(
    private authService: AuthenticationService,
    public permission: PermissionsService,
    public rolesService: RolesService,
    private _changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.isAdmin = this.rolesService.isSuperAdmin();
    this.user = this.authService.getUser();
  }

}
