import { Injectable } from '@angular/core';
import * as URL from '../../routes';
import { AuthenticationService } from '../authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '../../_models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) { }

  public async addCategory(paramName: string): Promise<any> {
    if (this.authService.isLogged()) {
      const params = {
        name: paramName
      };
      return await this.httpClient
        .post(URL.ROUTE_CATEGORY, params)
        .toPromise();
    } else {
      return false;
    }
  }

  public async editCategory(id: number, paramName: string): Promise<any> {
    if (this.authService.isLogged()) {
      const params = {
        name: paramName,
      };
      return await this.httpClient
        .put(URL.ROUTE_CATEGORY + '/' + id, params)
        .toPromise();
    } else {
      return false;
    }
  }

  public getCategories(): Observable<Array<Category>> {
    return this.httpClient.get<Array<Category>>(URL.ROUTE_CATEGORY);
  }

  public getCategory(id): Observable<Category> {
    return this.httpClient.get<Category>(URL.ROUTE_CATEGORY + '/' + id);
  }

  public async delete(id): Promise<any> {
    if (this.authService.isLogged()) {
      return await this.httpClient
        .delete(URL.ROUTE_CATEGORY + '/' + id)
        .toPromise();
    } else {
      return false;
    }
  }
}
