import { Component, OnInit } from '@angular/core';
import { GenericProduct } from '../_models/generic-product.model';
import { GenericProductsService } from '../_services/generic-products.service';
import { DATATABLE_CONFIG } from '../constants';
import { Router } from '@angular/router';
import { SubMenuService } from '../_services/subMenu.service';

declare var $;

@Component({
  selector: 'app-generic-products',
  templateUrl: './generic-products.component.html',
  styleUrls: ['./generic-products.component.scss']
})
export class GenericProductsComponent implements OnInit {

  genericProducts: any[] /*GenericProduct[]*/ = [];
  success = '';
  error = '';
  genericProductToShow: GenericProduct = new GenericProduct();
  toDelete: GenericProduct = new GenericProduct();
  emptyList = null;

  constructor(
    private genericProductsService: GenericProductsService,
    private subMenuService: SubMenuService,
    private router: Router
  ) { }

  async ngOnInit() {
    const subMenus = await this.subMenuService.getSubMenus().toPromise();
    this.genericProductsService.all().toPromise()
    .then(data => {
      this.genericProducts = data.map(gp => {
        const item: any = gp;
        for (const subMenu of subMenus) {
          if (subMenu.id === item.submenu_id) {
            item.submenuName = subMenu.name;
            break;
          }
        }
        return item;
      });
      if (!data || data.length === 0) {
        this.emptyList = true;
      }
      this.jQueryInit();
    }).catch(err => console.log(err));
  }


  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }


  goToEdit(id: number) {
    this.router.navigate(['generic-products/edit', id]);
  }

  goToProductList(id: number) {
    this.router.navigateByUrl(`generic-products/${id}/products`);
  }


  confirmDelete(genericProduct: GenericProduct) {
    this.toDelete = genericProduct;
    $('#confirmDeleteModal').modal('toggle');
  }

  delete(genericProduct: GenericProduct) {
    this.genericProductsService.delete(genericProduct.id).toPromise()
      .then(() => {
        $('.data-table-default').DataTable(DATATABLE_CONFIG).row($('#tr' + genericProduct.id)).remove().draw(); // update view
        this.genericProducts = this.genericProducts.filter(item => item.id !== genericProduct.id); // update logic
        this.success = `${genericProduct.name} a été supprimé avec succès !`; // show success message*/
        if (!this.genericProducts || this.genericProducts.length === 0) {
          this.emptyList = true;
        }
      })
      .catch(err => this.error = err.message);
  }

  showImage(pGenericProduct: GenericProduct) {
    this.genericProductToShow = pGenericProduct;
    $('#showImagesModal').modal('toggle');
  }

}
