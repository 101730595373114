import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../_services/menu.service';
import { Menu } from '../_models/menu.model';
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.css']
})
export class EditMenuComponent implements OnInit {

  createForm: FormGroup;
  isExpiredError = false;
  unknowError = false;
  menu: Menu;
  errorText = '';
  menuId = null;
  isLoading = false; // S'il vaut true, le loader est affiché dans le button de connexion
  isError = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSuccess = false; // S'il vaut true, le message d'erreur est affiché en haut du form
  isSubmitted = false; // Pou savoir si le formulaire a ete envoyé ou pas

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private menuService: MenuService) { }

  async ngOnInit() {
    this.actionService.addBackButton();
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      logo: ['']
    });
    this.menuId = this.route.snapshot.paramMap.get('id');
    await this.getAttribute();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createForm.get('logo').setValue(file);
    }
  }

  async getAttribute() {
    await this.menuService
      .getMenu(this.menuId)
      .toPromise()
      .then((data: Menu) => {
        this.menu = data;
        this.form.name.setValue(this.menu.name);
        this.form.description.setValue(this.menu.description);
      })
      .catch(err => {
        this.router.navigate(['/menus']);
      });
  }

  get form() {
    return this.createForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isExpiredError = false;
    this.unknowError = false;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      return;
    }

    const formData = new FormData();
    if (this.createForm.get('logo').value) {
      formData.append('logo', this.createForm.get('logo').value);
    }
    formData.append('name', this.form.name.value);
    formData.append('description', this.form.description.value);
    this.isLoading = true;
    this.menuService.editMenu(this.menuId, formData)
      .then(resp => {
        this.menuService.reloadMenus();
        this.isSuccess = true;
        this.router.navigate(['/menus']);
       })
      .catch(err => {
        this.isError = true;
        this.errorText = err;
       })
      .finally(() => this.isLoading = false);
  }

}
