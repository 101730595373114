import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as URL from '../routes';
import { City, Township, TownshipAdapter } from '../_models/township.model';

@Injectable({
  providedIn: 'root'
})
export class TownshipService {

  constructor(
    private http: HttpClient,
    private adapter: TownshipAdapter
  ) { }

  /**
   * Get all township
   */
  all(): Observable<Township[]> {
    return this.http.get(URL.TOWNSHIP).pipe(
      map((data: any[]) => data.map(item => this.adapter.fromJson(item))), // Adapt each item in the raw data array
    );
  }

  getTowshipsByCity(): Promise<any> {
    return this.http.get(URL.CITY + '/townships').toPromise();
  }


  /**
   * Create new service
   */
  create(formData: FormData): Observable<Township> {
    return this.http.post(URL.TOWNSHIP, formData).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }


  /**
   * get all cities
   */
   getCities(): Promise<City[]> {
    return this.http.get<City[]>(URL.CITY).toPromise()
  }


 /**
  * Delete service
  * @param id id of service to delete
  */
  delete(id: number) {
    return this.http.delete(URL.TOWNSHIP + '/' + id);
  }

  /**
   * Update service with given id
   */
  update(id: number, formData: FormData): Observable<Township> {
    return this.http.post(URL.TOWNSHIP + '/' + id, formData).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }



  /**
   * Find service with given id
   */
  find(id: number): Observable<Township> {
    const url = URL.SERVICES_ID.replace('{id}', `${id}`);
    return this.http.get(url).pipe(
      map(data => this.adapter.fromJson(data)),
    );
  }
}
