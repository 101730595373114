import { Component, OnInit } from '@angular/core';
import { MessageService } from '../_models/message-service.model';
import { MessageServiceService } from '../_services/message-service.service';
import { DATATABLE_CONFIG } from '../constants';
import { Service } from '../_models/service.model';
import { ServiceService } from '../_services/service.service';

declare var $;

@Component({
  selector: 'app-message-services',
  templateUrl: './message-services.component.html',
  styleUrls: ['./message-services.component.scss']
})
export class MessageServicesComponent implements OnInit {

  data: MessageService[] = [];
  success = '';
  error = '';
  serviceToShow: MessageService = new MessageService();
  toDelete: MessageService = new MessageService();
  toShow: MessageService = new MessageService();
  toShowService: Service = new Service();

  constructor(
    private messageServiceService: MessageServiceService,
    private serviceService: ServiceService,
  ) { }

  ngOnInit() {
    this.messageServiceService.all().subscribe(data => this.data = data);
    this.jQueryInit();
  }


  private jQueryInit() {
    if ($('tbody tr').length) {
      $('.data-table-default').DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  confirmDelete(messageService: MessageService) {
    this.toDelete = messageService;
    $('#confirmDeleteModal').modal('toggle');
  }

  show(messageService: MessageService) {
    this.toShow = messageService;
    this.serviceService.find(messageService.serviceId).subscribe(data => this.toShowService = data);
    $('#showModal').modal('toggle');
  }

  delete(messageService: MessageService) {
    this.messageServiceService.delete(messageService.id).toPromise()
      .then(() => {
        $('.data-table-default').DataTable(DATATABLE_CONFIG).row($('#tr' + messageService.id)).remove().draw(); // update view
        this.data = this.data.filter(item => item.id !== messageService.id); // update logic
        this.success = `Message service #${messageService.id} a été supprimé avec succès !`; // show success message
        this.error = '';
      })
      .catch(err => this.error = err.message);
  }

}
