import { Component, OnInit } from "@angular/core";
import { DATATABLE_CONFIG } from "../constants";
import { APIError } from "../_models/api-error.model";
import { City, Township } from "../_models/township.model";
import { TownshipService } from "../_services/township.service";

declare var $;

@Component({
  selector: "app-township",
  templateUrl: "./township.component.html",
  styleUrls: ["./township.component.scss"],
})
export class TownshipComponent implements OnInit {
  townships: Township[] = [];
  success = "";
  error = "";
  TownshipToShow: Township = new Township();

  township: Township = new Township();
  toUpdate: Township = new Township();
  cities: City[] = [];
  isLoading = true;
  name : any;

  constructor(private townshipService: TownshipService) {}

  ngOnInit() {
    this.townshipService.all().subscribe((data) => {
      this.townships = data;
      this.isLoading = false;
      this.jQueryInit();
      $(".select2").select2();
        $(".select2").on("change", (e) => (this.township.city_id = e.target.value));
        $(".select2").height(40);
    });

    this.townshipService.getCities().then((val) => {
      this.cities = val;
    });
  }

  private jQueryInit() {
    if ($("tbody tr").length) {
      $(".data-table-default").DataTable(DATATABLE_CONFIG);
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  goToEdit(township: Township) {
    this.name = township.name;
    this.township.name = township.name;
    this.township.description = township.description;
    this.township.id = township.id;
    $(".select2").val(township.city_id).trigger("change");
    $("#editModal").modal("toggle");
  }

  confirmDelete(township: Township) {
    this.township = township;
    $("#confirmDeleteModal").modal("toggle");
  }

  detail(township: Township) {
    this.township = township;
    $("#detailModal").modal("toggle");
  }

  delete(township: Township) {
    this.townshipService
      .delete(township.id)
      .toPromise()
      .then(() => {
        $(".data-table-default")
          .DataTable(DATATABLE_CONFIG)
          .row($("#tr" + township.id))
          .remove()
          .draw(); // update view
        this.townships = this.townships.filter(
          (item) => item.id !== township.id
        ); // update logic
        this.success = `${township.name} a été supprimé avec succès !`; // show success message
        this.error = "";
      })
      .catch((err) => (this.error = err.message));
  }

  update() {
    console.log(this.township);
    let formData = new FormData();
    formData.append("name", this.township.name);
    formData.append("city_id", "" + this.township.city_id);
    formData.append("description", this.township.description);

    this.townshipService
      .update(this.township.id, formData)
      .toPromise()
      .then(() => {
        this.townshipService.all().subscribe((data) => (this.townships = data));
        this.success = `${this.township.name} a été modifié avec succès !`; // show success message
        this.error = "";
      })
      .catch((err: APIError) => {
        this.error = err.message;
        this.success = "";
        if (err.errors.name) {
          this.error = "Il existe déjà un Township du même nom !";
        }
      });
  }
}
