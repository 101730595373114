import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from './_services/authentication.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
// import { DATATABLE_CONFIG } from './constants';
// import { ActionService } from './_services/actions/action.service';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Cameroun en 1 clique';
  showNavBarAndSideBar = false;
  private sub: Subscription;

  constructor(
    private authService: AuthenticationService,
    //private actionService: ActionService,
    private router: Router
  ) { }


  ngOnInit() {
    this.sub = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateNavBarAndSideBar();
          $('.dropify').dropify({});
          this.jQueryInit();
        }
      });
  }

  public jQueryInit() {
    if ($('tbody tr').length) {
      //$('.data-table-default').DataTable(DATATABLE_CONFIG);
      $('.carousel').carousel({
        interval: 1000,
        keyboard: true,
        touch: true
      });
    } else {
      setTimeout(this.jQueryInit, 250);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateNavBarAndSideBar() {
    const bool: boolean = document.URL
      && !document.URL.includes('login')
      && !document.URL.includes('404')
      && this.authService.isLogged();

    if (this.showNavBarAndSideBar !== bool) {
      this.showNavBarAndSideBar = bool;
    }
  }

  onClose(event: Event) {
    this.authService.logout();
  }

}
