import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as URL from '../../routes';
import * as STORE from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient) { }

  /**
   * @ngdoc function
   * @author Bill Gates Happi Happi
   * @param userId Ce paramètre contient l'id de l'utilisateur
   * @return Un promise des permissions de l'utilisateur connecté sur son interface de connexion
   */
  public async getPermissions(): Promise<any> {
    const headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Accept', 'application/json');
    // URL.USERS + userId + '/permissions'
    if (sessionStorage.getItem(STORE.STORED_USER_PERMISSIONS)) {
      const roles = JSON.parse(sessionStorage.getItem(STORE.STORED_USER_PERMISSIONS));
      return Promise.resolve(roles);
    }

    return await this.httpClient
      .get(URL.CONNECTED_USER_PERMISSIONS, {
        headers,
        withCredentials: true
      })
      .toPromise()
      .then(response => {
        sessionStorage.setItem(STORE.STORED_USER_PERMISSIONS, JSON.stringify(response));
        return Promise.resolve(response);
      })
      .catch(err => Promise.resolve(err));
  }

  public getUserPermissions() {
    const perms = sessionStorage.getItem(STORE.STORED_USER_PERMISSIONS);
    if ( perms == null ) {
      return perms;
    }
    return JSON.parse(perms);
  }

  /**
   * @description Vérifie si l'utilisateur connecté possède le requis pour afficher une interface
   * @author Bill Gates Happi Happi
   */
  public hasPermission(toCheck: string) {
    // const user: any = this.authService.getUser();
    const userPermissions = this.getUserPermissions();
    if ( userPermissions ) {
      for (const permis of userPermissions ) {
        if (permis.name.toLowerCase() === toCheck.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  }
}
