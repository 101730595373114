import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIError } from 'src/app/_models/api-error.model';
import { MenuService } from '../_services/menu.service';
import { NotificationService } from '../_services/notification.service';
import { ActionService } from '../_services/actions/action.service';

@Component({
  selector: 'app-create-notification',
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.scss']
})
export class CreateNotificationComponent implements OnInit {
  @ViewChild('File', { static: false }) InputFile;

  createForm: FormGroup;
  error = '';
  success = '';
  isSuccess = false;
  loading = false;
  submitted = false;
  menus = [];
  UploadFile: File[];

  constructor(
    private formBuilder: FormBuilder,
    private notifService: NotificationService,
    private menuService: MenuService,
    private actionService: ActionService
  ) { }

  ngOnInit() {
    this.actionService.addBackButton();
    this.menuService.getMenus().subscribe(data => this.menus = data);
    // Validateur du formulaire
    this.createForm = this.formBuilder.group({
      link: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.minLength(2)]],
      photos: ['', Validators.required],
      menu_id: ['', Validators.required]
    });
  }

  get form() {
    return this.createForm.controls;
  }

  upload(data: any): void {
    const formData = new FormData();
    const file = this.InputFile.nativeElement;
    if (file.files.length > 0) {
      for (const blob of file.files) {
        formData.append('photos[]', blob);
      }
    }
    formData.append('link', data.link.value);
    formData.append('message', data.message.value);
    formData.append('menu_id', data.menu_id.value + '');

    this.notifService.uploadNotification(data.menu_id.value, formData)
      .then((response) => {
        this.isSuccess = true;
        this.success = 'Notification créée avec succès !';
        this.submitted = false;
      }).catch((err: APIError) => {
        this.error = err.message;
        console.log('Dans catch ', err);
        // Try to show first error you see
        if (err.status === '400' && err.errors) {
          for (let key in err.errors) {
            this.error = err.errors[key][0];
            break;
          }
        }
      })
      .finally(() => {
        this.loading = false;
        this.actionService.scrollTop();
        this.createForm.reset();
      });

  }

  onSubmit() {
    this.submitted = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.error = 'Formulaire incorrect !';
      return;
    } else {
      this.error = '';
    }
    alert("Bon jusqu'ici");
    this.loading = true;
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.createForm.invalid) {
      this.actionService.scrollTop();
      return;
    }
    this.upload(this.form);
    this.loading = true;
  }

}
